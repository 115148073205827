<template>
  <div class="app--chat--field">
    <div
      v-if="!currentConversationId"
      class="empty-field"
    >
      <v-img
        max-width="186"
        max-height="156"
        :src="require('@/assets/png/empty-chat.png')"
      />
    </div>
    <v-skeleton-loader
      v-else-if="loadingConversations || loadingMessage"
      :loading="loadingConversations || loadingMessage"
      :style="{ height: '100%', width: '100%' }"
      type="header, body, table-tfoot"
      :types="{
        test: 'avatar, text',
        header: 'list-item-avatar-two-line',
        body: 'image@4',
      }"
    />
    <div
      v-else
      id="conversationWrap"
      class="app--conversationWrapper"
    >
      <!-- status bar -->
      <conversation-status-bar
        :is-chat-edit.sync="isChatEdit"
        :search-string.sync="searchString"
        :search-choose.sync="searchChoose"
        :search-count="searchCount"
      />
      <div class="app--conversation--drop--wrap">
        Перетащите сюда фотографии, чтобы отправить их
      </div>

      <!-- лента чатов conversation-scroll-y -->
      <div
        id="conversationField"
        ref="conversationField"
        class="app--conversation--content"
        @scroll="scrollFeed"
      >
        <div
          ref="conversationFieldWrapper"
          class="content__wrapper"
          @scroll="scrollFeed"
        >
          <!-- прелоадер старых сообщений  -->
          <div
            v-for="(item, idx) in messages"
            :key="item.uuid"
            class="app--conversation--message-box"
          >
            <div
              :id="`message-${item.id}`"
              :style="Object.keys(messages).indexOf(idx) === 0 ? 'margin-top: 24px;' : ''"
            >
              <message
                :messages="messages"
                :item="item"
                :my-message="
                  chatUser.id == item.sender_id &&
                    (profile.id == item.real_sender_id || !realChatName)"
                :is-close-action.sync="isMessageCloseActions"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- форма отправки -->
      <conversation-send-box
        @message-sent="toBottomFeed()"
        @attach-files="toogleDialogAttahcFile"
      />
    </div>
    <!-- панель переотпраки сообщения -->
    <forward-panel />
    <!-- панель тем -->
    <topic-panel />
    <!-- диалог прикрипления файлов -->
    <attach-files
      :show.sync="showDialogAttahcFile"
      @message-sent="toBottomFeed()"
    />
    <!-- панель добавления участника в чат -->
    <chat-add-member
      v-model="isChatAddMember"
    />
    <!-- панель редактирования чата -->
    <chat-edit-group
      v-if="isGroup"
      v-model="showChatEditPanel"
      @openAddMemberPanel="isChatAddMember = true"
    />
    <chat-edit-user
      v-else
      v-model="showChatEditPanel"
    />
    <!-- панель информация о клиенте -->
    <side-panel-edit-client
      v-model="clientInfoPanel.active"
      :data="clientInfoPanel.data"
    />
  </div>
</template>

<script>
  // components
  import ConversationStatusBar from './ConversationStatusBar'
  import ConversationSendBox from './ConversationSendBox'
  import ForwardPanel from './components/chat/ForwardPanel'
  import ChatEditGroup from './components/chat/chat-edit/ChatEditGroup'
  import ChatAddMember from './components/chat/ChatAddMember'
  import ChatEditUser from './components/chat/chat-edit/ChatEditUser'
  import AttachFiles from './components/sendbox/AttachFiles'
  import TopicPanel from './components/chat/TopicPanel'
  import Message from './message/Message'

  // mixins
  import MixinValidate from '../mixins/conversation-field/validate.js'
  import MixinMessage from '../mixins/conversation-field/message.js'
  import MixinSearch from '../mixins/conversation-field/search.js'
  import MixinIndex from '../mixins/index.js'
  import MixinData from '../mixins/conversation-field/data.js'

  export default {
    components: {
      ConversationStatusBar,
      ConversationSendBox,
      ForwardPanel,
      ChatEditGroup,
      ChatAddMember,
      ChatEditUser,
      AttachFiles,
      TopicPanel,
      Message,
    },
    mixins: [
      MixinValidate,
      MixinMessage,
      MixinSearch,
      MixinIndex,
      MixinData,
    ],
    data () {
      return {
        // search
        searchString: '',
        searchChoose: 0,
        searchCount: 0,
        searchFound: [],
        // scroll
        messageIdToScrollPage: null,
        // Booleans
        loadingMessage: false,
        // message
        isMessageCloseActions: false,
        // shows
        showDialogAttahcFile: false,
        // chat edit
        isChatEdit: false,
        // chat add member
        isChatAddMember: false,
      }
    },
    watch: {
      async currentConversationId (v) {
        this.$store.commit('chat/sendbox/clearAllModes')
        if (v) {
          // при переходе в другой чат обнуляем
          this.messageIdToScrollPage = null
          await this.fetchData(v)
          await this.$nextTick()
          this.toBottomFeed()
        }
      },
      async messages () {
        // Перемещение ленты чата на сообщение с которого началась загрузка страницы сообщений
        await this.$nextTick()
        if (this.issetMessages && !this.loadingMessagePage) {
          this.updateMessages()
        }
        if (document.getElementById(this.messageIdToScrollPage)) {
          const msg = document.getElementById(this.messageIdToScrollPage)

          if (msg) {
            const conversationFieldWrapper = this.$refs?.conversationFieldWrapper
            if (conversationFieldWrapper) {
              conversationFieldWrapper.scrollTop = msg.offsetTop - 290
            } // 115 px поправка скрола
          }
        } else {
          this.toBottomFeed()
        }
      },
    },
    updated () {
      this.wrapperOverflowX()
    },
    async mounted () {
      if (this.currentConversationId) {
        await this.fetchData(this.currentConversationId)
        await this.$nextTick()
        this.wrapperOverflowX()
      }
      if (this.issetMessages) {
        this.updateMessages()
        setTimeout(() => this.toBottomFeed(), 2000)
      }
    },
    methods: {
      toogleDialogAttahcFile (files) {
        this.showDialogAttahcFile = !this.showDialogAttahcFile
        if (files && files.length) {
          const filesArray = []
          files.forEach(f => {
            filesArray.push(f)
          })
          let newFiles = this.$store.getters['chat/sendbox/fileForwarding'].concat(filesArray)
          newFiles = Array.from(new Set(newFiles))
          this.$store.commit('chat/sendbox/fileForwarding', newFiles)
        }
      },
      wrapperOverflowX () {
        if (this.$refs?.conversationFieldWrapper) {
          if (
            this.$refs.conversationField.clientHeight > this.$refs.conversationFieldWrapper.clientHeight
          ) {
            this.$refs.conversationFieldWrapper.style.overflowX = 'visible'
          } else {
            this.$refs.conversationFieldWrapper.style.overflowX = 'hidden'
          }
        }
      },
    },
  }
</script>
