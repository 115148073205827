<template>
  <div
    class="track-wave"
  >
    <slot
      name="play"
      :is-play="isPlay"
      :switchPlayBack="switchPlayBack"
    >
      <iconify-icon
        v-if="!isPlay"
        class="icon icon-play"
        icon="feather-play"
        width="21"
        @click="switchPlayBack"
      />
      <iconify-icon
        v-else
        class="icon icon-play"
        icon="ion-pause-outline"
        width="21"
        @click="switchPlayBack"
      />
    </slot>
    <div
      v-if="isAudioLoaded"
      class="strips"
    >
      <div
        v-for="(v, i) in recordVolumes"
        :key="i"
        class="strip"
        :class="[isStripActive(i) ? 'active' : '']"
        :style="`height: ${v}px`"
      />
    </div>
    <div
      v-else
      class="strips strips--loading"
    >
      <div
        v-for="(v, i) in audioDefault.strip"
        :key="i"
        class="strip"
        :class="[v.active ? 'active' : '']"
        :style="`height: 4px`"
      />
    </div>
    <slot
      v-if="duration"
      name="duration"
      :is-audio-loaded="isAudioLoaded"
      :is-play="isPlay"
      :recorded-time="recordedTime"
      :record-duration="recordDuration"
    >
      <div class="durations">
        <p class="body-m-regular neutral-600--text">
          {{ isAudioLoaded && isPlay ? recordedTime : recordDuration }}
        </p>
      </div>
    </slot>
  </div>
</template>

<script>
  import ApiService from '@/api/api-client'
  import { convertTimeMMSS } from '@/utils'
  import { filterAudioData, normalizeAudioData } from '@/utils/audio'

  export default {
    props: {
      content: {
        type: Object,
        required: true,
      },
      source: {
        type: [AudioBufferSourceNode, Object, undefined],
        required: false,
        default: () => {
          return {}
        },
      },
      stripCount: {
        type: [Number, String],
        default: 52,
      },
      stripMaxHeight: {
        type: [Number, String],
        default: 21,
      },
      stripMinHeight: {
        type: [Number, String],
        default: 4,
      },
      duration: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
        isPlay: false,
        audioCurrentTime: NaN,
        isAudioLoaded: false,
        isAudioLoading: false,
        recordDuration: '00:00',
        recordVolumes: [],
        audioPlay: new Audio(),
        audioCtx: new (window.AudioContext || window.webkitAudioContext)(),
        audioSource: {}, // initAudioData
        audioStripInterval: null,
        audioDefault: {
          strip: Array(this.stripCount).fill({ active: false }),
        },
      }
    },
    computed: {
      recordedTime () {
        if (this.audioCurrentTime) {
          return convertTimeMMSS(this.audioCurrentTime)
        }
        return '00:00'
      },
    },
    watch: {
      isAudioLoaded (v) {
        if (v) {
          if (this.audioStripInterval) {
            clearInterval(this.audioStripInterval)
          }
          if (this.audioSource.buffer.duration < 1) {
            this.recordDuration = '00:01'
          } else {
            this.recordDuration = convertTimeMMSS(this.audioSource.buffer.duration)
          }
          this.loadVolumes()
        }
      },
      isAudioLoading (v) {
        if (v && !this.audioStripInterval) {
          const _vm = this
          this.audioStripInterval = setInterval(() => {
            const bandCount = 5
            const activeIdx = _vm.audioDefault.strip.findIndex(a => a.active)
            _vm.audioDefault.strip = _vm.audioDefault.strip.map(a => { return { active: false } })
            if (activeIdx !== -1) {
              if (activeIdx + (bandCount * 2) + 1 < _vm.audioDefault.strip.length) {
                for (let i = activeIdx + bandCount; i < activeIdx + (bandCount * 2); i++) {
                  _vm.audioDefault.strip[i] = { active: true }
                }
              } else {
                for (let i = 0; i < bandCount; i++) {
                  _vm.audioDefault.strip[i] = { active: true }
                }
              }
            } else {
              for (let i = 0; i < bandCount; i++) {
                _vm.audioDefault.strip[i] = { active: true }
              }
            }
          }, 100)
        }
      },
    },
    async mounted () {
      await this.loadData()
      this.eventAudioPlayTimeUpdate()
      this.eventAudioPlayEnded()
    },
    beforeDestroy () {
      this.audioPlay.pause()
      this.audioPlay = {}
      this.audioSource = {}
    },
    methods: {
      async switchPlayBack () {
        if (this.isAudioLoading) {
          this.$notify({
            type: 'error',
            group: 'api',
            title: 'Загрузка...',
            text: 'Пожалуйста подождите! <br> Аудио загружается',
          })
          return
        } else if (!this.isAudioLoaded) {
          await this.loadData()
          return
        }

        if (this.isPlay) {
          this.audioPlay.pause()
        } else {
          this.audioPlay.play()
        }
        this.isPlay = !this.isPlay
      },
      isStripActive (idx) {
        const duration = (this.audioPlay.duration * 1000)
        const part = (duration / this.stripCount)
        return (part * idx) <= (this.audioCurrentTime * 1000)
      },
      async loadData () {
        this.audioPlay.src = this.content.url
        await this.initAudioData()
      },
      loadVolumes () {
        const rawData = this.audioSource.buffer.getChannelData(0)
        const filteredData = filterAudioData(rawData, this.stripCount)
        const normalizeData = normalizeAudioData(filteredData)
        this.recordVolumes = normalizeData.map(v => {
          const reduced = Math.round(v * this.stripMaxHeight)
          return (reduced <= this.stripMinHeight)
            ? this.stripMinHeight
            : reduced
        })
      },
      initAudioData () {
        if (this.source && this.source instanceof AudioBufferSourceNode) {
          this.audioSource = this.source
          this.isAudioLoaded = true
          return
        }

        this.audioSource = this.audioCtx.createBufferSource()
        this.isAudioLoading = true
        ApiService.get(this.content.url, {
          responseType: 'arraybuffer',
        })
          .then(response => {
            return this.audioCtx.decodeAudioData(response.data || response)
          })
          .then(buffer => {
            this.isAudioLoaded = true
            this.audioSource.buffer = buffer
            this.$emit('audioSource', this.audioSource)
          })
          .finally(() => {
            this.isAudioLoading = false
          })
      },
      eventAudioPlayEnded () {
        this.audioPlay.onended = () => {
          if (this.isPlay) this.isPlay = false
        }
      },
      eventAudioPlayTimeUpdate () {
        this.audioPlay.ontimeupdate = () => {
          this.audioCurrentTime = this.audioPlay.currentTime
        }
      },
    },
  }
</script>
