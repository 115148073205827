<template>
  <v-dialog
    v-model="internalShow"
    max-width="80%"
    content-class="dialog-attachment-video dialog-attachment-preview"
  >
    <div class="preview-img">
      <video-player
        ref="videoPlayer"
        class="video-player"
        :options="playerOptions"
        :playsinline="true"
      />
    </div>
  </v-dialog>
</template>

<script>
  import 'video.js/dist/video-js.css'
  import { videoPlayer } from 'vue-video-player'

  export default {
    components: {
      videoPlayer,
    },
    props: {
      show: Boolean,
      content: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        internalShow: false,
        playerOptions: {
          // videojs options
          height: '500',
          autoplay: false,
          muted: false,
          language: 'ru',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [],
        },
      }
    },
    computed: {
    },
    watch: {
      show (v) {
        this.internalShow = v
      },
      internalShow (v) {
        this.$emit('update:show', v)
      },
      content (v) {
        this.playerOptions.sources = {
          type: this.content.mime,
          src: this.content.url,
        }
      },
    },
    mounted () {
      this.playerOptions.sources = {
        type: this.content.mime,
        src: this.content.url,
      }
    },
  }
</script>
