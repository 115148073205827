<template>
  <div style="position: absolute; width: 100%; height: 100%">
    <v-scroll-x-transition hide-on-leave>
      <add-member
        v-show="isComponentAddMember"
        :show="isComponentAddMember"
        :title="addMemberTitle"
        :edit-topic-id="editTopicId"
        :selected-members.sync="selectedMembers"
        @back="closePanel()"
      />
    </v-scroll-x-transition>
    <v-scroll-x-reverse-transition hide-on-leave>
      <new-topic
        v-show="isComponentNewTopic"
        :show="isComponentNewTopic"
        :init-component.sync="isInitComponentNew"
        :selected-members.sync="selectedMembers"
        @add-member="isComponentAddMember = true"
        @close="closePanel()"
      />
    </v-scroll-x-reverse-transition>
    <v-scroll-x-reverse-transition hide-on-leave>
      <edit-topic
        v-show="isComponentEditTopic"
        :show="isComponentEditTopic"
        :selected-members.sync="selectedMembers"
        :edit-topic-id="editTopicId"
        @add-member="isComponentAddMember = true"
        @close="closePanel()"
      />
    </v-scroll-x-reverse-transition>
    <v-scroll-x-transition hide-on-leave>
      <div
        v-show="!isComponentNewTopic && !isComponentEditTopic && !isComponentAddMember"
        class="group-topic"
      >
        <div class="group-topic__header">
          <p class="title-m-bold neutral-900--text">
            Темы чата «{{ conversationName }}»
          </p>
          <base-text-field
            v-model="searchTopic"
            class="field-search"
            placeholder="Поиск тем"
            prepend-inner-icon="$iconify_ion-search-outline"
            clear-icon="$iconify_ion-close-circle-outline"
            :prepend-inner-icon-color="this.$vuetify.theme.themes.light['neutral-500']"
            clearable
            hide-details
          />
        </div>
        <div
          class="group-topic__content"
        >
          <ul
            v-if="topics.length"
            ref="topicList"
            class="topics__list"
          >
            <li
              v-for="topic in topicsFiltered"
              :key="topic.id"
              class="topic__item"
            >
              <div class="left-block">
                <p class="topic-name body-l-semibold neutral-900--text">
                  {{ topic.name }}
                </p>
                <p class="members-names body-s-medium neutral-500--text">
                  {{ getTopicMembersName(topic.id) }}
                </p>
              </div>
              <div class="right-block">
                <v-btn
                  class="btn-edit primary-100"
                  @click="editTopic(topic.id)"
                >
                  <iconify-icon
                    class="icon-edit primary--text"
                    icon="feather-edit-2"
                    width="15"
                  />
                </v-btn>
              </div>
            </li>
          </ul>
          <div class="topic__create">
            <v-btn
              class="create__btn"
              color="primary"
              @click="openComponentNew()"
            >
              <iconify-icon
                icon="feather-plus-circle"
                class="icon-plus"
                width="21"
              />
              <p class="body-m-semibold neutral-100--text">
                Новая тема
              </p>
            </v-btn>
          </div>
        </div>
      </div>
    </v-scroll-x-transition>
  </div>
</template>

<script>
  // mixins
  import MixinIndex from '@/views/communications/mixins/index.js'
  // other
  import FuzzySearch from 'fuzzy-search'
  // components
  import NewTopic from './group-topic/New'
  import AddMember from './group-topic/AddMember'
  import EditTopic from './group-topic/Edit'

  export default {
    components: {
      NewTopic,
      AddMember,
      EditTopic,
    },
    mixins: [
      MixinIndex,
    ],
    props: {
      clickBackBtn: Boolean,
      activeSidePanel: Boolean,
    },
    data () {
      return {
        searchTopic: '',
        editTopicId: NaN,
        addMemberTitle: '',
        isComponentNewTopic: false,
        isComponentEditTopic: false,
        isComponentAddMember: false,
        isInitComponentNew: false,
        selectedMembers: [],
        callFromOutside: false,
      }
    },
    computed: {
      conversationId () {
        return this.$store.getters['chat/conversation/currentConversationId']
      },
      conversation () {
        return this.$store.getters['chat/data/conversation'](this.conversationId)
      },
      conversationName () {
        return this.conversation.display_name && this.conversation.display_name.length
          ? this.conversation.display_name
          : 'Без названия'
      },
      topicPanelModes () {
        return this.$store.getters['chat/sendbox/topicPanel']
      },
      members () {
        return this.$store.getters['chat/data/members'](this.conversationId)
      },
      activeMembers () {
        return this.members.filter(m => m.active)
      },
      topicsSearcher () {
        return new FuzzySearch(
          this.topics,
          ['name'],
          {
            caseSensitive: false,
          },
        )
      },
      topicsFiltered () {
        let topicsFiltered = this.topics
        const search = String(this.searchTopic).replace(/\s+/g, ' ').replace(/^\s/g, '')

        if (this.searchTopic && search.length) {
          topicsFiltered = this.topicsSearcher.search(search.toLowerCase())
        }

        return topicsFiltered
      },
      topics () {
        return this.$store.getters['chat/topic/topics']
      },
    },
    watch: {
      isComponentNewTopic (v) {
        if (v) {
          this.isComponentEditTopic = false
          if (!this.isComponentAddMember) {
            this.selectedMembers = []
          } else {
            this.isComponentAddMember = false
          }
          this.editTopicId = NaN
          this.addMemberTitle = 'Новая тема'
        }
      },
      isComponentEditTopic (v) {
        if (v) {
          this.isComponentNewTopic = false
          this.isComponentAddMember = false
          this.addMemberTitle = 'Редактирование темы'
        }
      },
      isComponentAddMember (v) {
        if (v) {
          this.isComponentNewTopic = false
          this.isComponentEditTopic = false
        }
      },
      clickBackBtn () {
        this.closePanel()
      },
      topicPanelModes: {
        deep: true,
        handler (v) {
          if (v.mode.length) {
            this.modesHandler()
          }
        },
      },
      activeSidePanel (v) {
        if (!v) {
          this.callFromOutside = false
        }
      },
    },
    mounted () {
      this.isComponentNewTopic = false
      this.isComponentEditTopic = false

      this.$nextTick(() => {
        window.addEventListener('resize', this.listMaxHeightTopics)
      })
    },
    updated () {
      this.listMaxHeightTopics()
    },
    methods: {
      listMaxHeightTopics () {
        if (this.$refs?.topicList) {
          this.$refs.topicList.style.maxHeight = document.body.clientHeight - 330 - (34 - 19) + 'px'
        }
      },
      closePanel () {
        if (this.isComponentAddMember) {
          if (!isNaN(this.editTopicId)) {
            this.isComponentEditTopic = true
          } else {
            this.isComponentNewTopic = true
          }
          return
        }

        // external call
        if (
          this.callFromOutside &&
          this.topicPanelModes?.call?.direction &&
          this.topicPanelModes.call.of === 'ChatEditGroup'
        ) {
          const topicPanelModes = Object.assign({}, this.topicPanelModes)
          topicPanelModes.call.direction = 'reception'
          this.$store.commit('chat/sendbox/topicPanel', topicPanelModes)
          this.$emit('closePanel')
        }

        if (
          this.isComponentNewTopic ||
          this.isComponentEditTopic
        ) {
          this.isComponentNewTopic = false
          this.isComponentEditTopic = false
          return
        }

        if (
          !this.isComponentNewTopic &&
          !this.isComponentEditTopic &&
          !this.isComponentAddMember
        ) {
          this.$emit('closePanel')
        }
      },
      getTopicMembersName (topicId) {
        const topic = this.topics.find(t => t.id === topicId)
        const topicMembers = Object.assign([], topic.members)
        const names = []

        if (topicMembers.length) {
          if (topicMembers.length === this.activeMembers.length) return 'Все участники чата'

          this.activeMembers.forEach(am => {
            topicMembers.forEach(m => {
              if (am.id === m.id) names.push(am.name)
            })
          })
        } else {
          return 'Нету участников'
        }

        return names.join(', ')
      },
      modesHandler () {
        this.callFromOutside = true
        this.selectedMembers = []
        if (this.topicPanelModes.mode === 'create') {
          this.isComponentNewTopic = true
        } else if (this.topicPanelModes.mode === 'edit') {
          this.editTopicId = this.topicPanelModes.editId
          this.isComponentEditTopic = true
        }

        const topicPanel = Object.assign({}, this.topicPanelModes)
        topicPanel.mode = ''
        topicPanel.editId = NaN
        this.$store.commit('chat/sendbox/topicPanel', topicPanel)
      },
      editTopic (topicId) {
        this.selectedMembers = []
        this.editTopicId = topicId
        this.isComponentEditTopic = true
      },
      openComponentNew () {
        this.isInitComponentNew = true
        this.isComponentNewTopic = true
      },
    },
  }
</script>
