<template>
  <div
    class="app-conversation-send-box"
    :class="[portableClass, {portable: isPortable}]"
  >
    <div
      class="app--conversation--sendForm"
    >
      <app-topic
        v-if="isTopic && !isBlock"
      />

      <app-reply
        v-if="isReply && !isPortable && !isBlock"
        :msg-item="messages[replyMessageId]"
      />

      <app-edit
        v-if="isEdit && !isPortable && !isBlock"
        :msg-item="messages[editMessageId]"
      />

      <div
        class="app--conversation--sendField"
        :class="[
          isReply && !isBlock ? 'reply' :
          isEdit && !isBlock ? 'edit' :
          isTopic && !isBlock ? 'topic' :
          (message && message.length) ? 'writes' : '',
          {emoji: isEmoji && !isBlock}
        ]"
      >
        <div
          id="messageWrapper"
        >
          <v-row
            no-gutters
            style="height: 100%"
          >
            <!-- ==== Choice ==== -->
            <app-choice
              v-if="isChoice && choiceMessageIds.length > 0"
            />
            <!-- ==== Record ==== -->
            <app-record-start
              v-if="isRecording"
              :is-recording="isRecording"
              :is-stop.sync="isStop"
              :record-list.sync="recordList"
              :record-play.sync="recordPlay"
              :play-current-time.sync="audioPlayCurrentTime"
              :recorder="recorder"
            />
            <!-- ==== Record stop ==== -->
            <app-record-stop
              v-if="!isRecording && isStop && recordList.length"
              ref="recordStop"
              :is-recording="isRecording"
              :is-play.sync="isPlay"
              :is-stop.sync="isStop"
              :track-wave-count="trackWaveCount"
              :track-wave-min-height="trackWaveMinHeight"
              :record-list.sync="recordList"
              :record-play.sync="recordPlay"
              :play-current-time.sync="audioPlayCurrentTime"
              :recorder="recorder"
              @messageSent="$emit('message-sent')"
            />
            <!-- ==== Text ==== -->
            <div
              v-if="!isRecording && !isStop && !(isChoice && choiceMessageIds.length)"
              class="app-conversation--sendForm__message__text"
              :class="[(message && message.length) ? 'writes' : '']"
            >
              <!-- Attach -->
              <div
                v-if="(!message || !message.length) && !isPortable"
                class="attach"
              >
                <iconify-icon
                  class="icon icon-attach"
                  icon="ion-attach"
                  width="21"
                  @click="clickAttachIcon"
                />
                <form ref="attachedForm">
                  <input
                    ref="attachedFile"
                    type="file"
                    class="d-none"
                    multiple
                    @change="changeAttachFiles"
                  >
                </form>
              </div>
              <iconify-icon
                v-else-if="!isPortable"
                class="icon icon-close"
                icon="ion-close-circle-outline"
                width="21"
                @click="clearMessage"
              />
              <div
                class="message-block"
              >
                <label for="messageTextArea" />
                <v-textarea
                  id="messageTextArea"
                  ref="messageTextArea"
                  v-model="message"
                  class="message-text-area"
                  rows="1"
                  row-height="20"
                  auto-grow
                  hide-details
                  :disabled="isSend"
                  placeholder="Напишите сообщение..."
                  @keyup.ctrl.enter="addLine($event)"
                  @keypress.enter.exact.stop="send()"
                />
              </div>
              <div class="left-block">
                <!-- Hash Icon -->
                <iconify-icon
                  class="icon icon-hash"
                  icon="feather-hash"
                  width="21"
                  @click="toogleTopic"
                />
                <!-- Smile Icon -->
                <div
                  v-click-outside="closeEmoji"
                  class="emoji-block"
                >
                  <iconify-icon
                    class="icon icon-smile"
                    icon="feather-smile"
                    width="21"
                    @click="toogleEmoji"
                  />
                  <app-emoji v-show="isEmoji && !isBlock" />
                </div>
                <!-- Microphone Icon -->
                <iconify-icon
                  v-if="showMicrophoneIcon"
                  id="record"
                  class="icon icon-mic"
                  icon="feather-mic"
                  width="21"
                  @click="toggleRecorder()"
                />
                <!-- Send Icon -->
                <v-btn
                  v-if="showSendIcon"
                  class="send-button"
                  color="neutral-500"
                  text
                  :loading="isSend"
                >
                  <iconify-icon
                    class="icon icon-send"
                    icon="feather-send"
                    width="21"
                    @click="send"
                  />
                </v-btn>
              </div>
            </div>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // components
  import AppEdit from './components/sendbox/Edit'
  import AppReply from './components/sendbox/Reply'
  import AppEmoji from './components/sendbox/Emoji'
  import AppTopic from './components/sendbox/Topic'
  import AppChoice from './components/sendbox/Choice'
  import AppRecordStop from './components/sendbox/RecordStop'
  import AppRecordStart from './components/sendbox/RecordStart'

  // mixins
  import MixinRecorder from '../mixins/conversation-send-box/recorder.js'
  import MixinMessage from '../mixins/conversation-send-box/message.js'
  import MixinTyping from '../mixins/conversation-send-box/typing.js'
  import MixinIndex from '../mixins/index.js'
  import MixinData from '../mixins/conversation-send-box/data.js'
  import vClickOutside from 'v-click-outside'

  export default {
    directives: {
      clickOutside: vClickOutside.directive,
    },
    components: {
      AppEdit,
      AppReply,
      AppEmoji,
      AppTopic,
      AppChoice,
      AppRecordStop,
      AppRecordStart,
    },
    mixins: [
      MixinRecorder,
      MixinMessage,
      MixinTyping,
      MixinIndex,
      MixinData,
    ],
    props: {
      isPortable: {
        type: Boolean,
        required: false,
        default: false,
      },
      portableClass: {
        type: String,
        default: 'portable--default',
      },
    },
    data () {
      return {
        // audio
        attempts: 5,
        recorder: this._initRecorder(),
        recordList: [],
        recordPlay: {},
        audioPlayCurrentTime: 0,
        // recorder boolean
        isStop: false,
        isPlay: false,
        // track wave
        trackWaveCount: 100,
        trackWaveMaxHeight: 21,
        trackWaveMinHeight: 4,
        // message
        message: '',
        messagesCount: 0,
        // typing
        typingTime: null,
        beforeTheBlock: {},
      }
    },
    computed: {
      showMicrophoneIcon () {
        if (this.isPortable) return false
        return !(this.message && this.message.length)
      },
      showSendIcon () {
        if (this.isPortable) return true
        return (this.message && this.message.length)
      },
      isPortableBlock () {
        return this.$store.getters['chat/sendbox/isPortableBlock']
      },
      isBlock () {
        return (!this.isPortable && this.isPortableBlock)
      },
    },
    watch: {
      async message (v) {
        if (this.isBlock) return

        this.limitTextArea()
        if (!v || !v.length) {
          this.setTemplateMessage('')
        }
        this.$store.commit('chat/sendbox/messageText', v)
        await this.sendTypingEvent(this.conversationId)
      },
      isRecording (v) {
        if (v) {
          this.$store.commit('chat/sendbox/clearAllModesBoolExceptCurrent', 'isReply')
        }
      },
      messageText (v) {
        if (this.isBlock) return
        this.setTemplateMessage(v)
        this.message = v
      },
      async isEdit (v) {
        if (this.isBlock) return

        if (v) {
          this.$store.commit('chat/sendbox/clearAllModesBoolExceptCurrent', 'isEdit')
        } else {
          this.message = ''
        }
      },
      editMessageId (v) {
        if (this.isBlock) return

        if (v && !isNaN(v)) {
          this.$store.commit('chat/sendbox/editMessageTextOld', this.messages[v].message)
          this.message = this.messages[v].message
        }
      },
      isReply (v) {
        if (this.isBlock) return

        if (v) {
          this.$store.commit('chat/sendbox/clearAllModesBoolExceptCurrent', 'isReply')
        }
      },
      isChoice (v) {
        if (this.isBlock) return

        if (v) {
          this.$store.commit('chat/sendbox/clearAllModesBoolExceptCurrent', 'isChoice')
        }
      },
      isTopic (v) {
        if (this.isBlock) return

        if (v) {
          this.$store.commit('chat/sendbox/clearAllModesBoolExceptCurrent', 'isTopic')
        }
      },
      conversationId () {
        this.cancelRecorder()
        if (this.conversation && Object.keys(this.conversation).includes('currentTemplateMessage')) {
          this.message = this.conversation.currentTemplateMessage
        } else {
          this.message = ''
        }
      },
    },
    methods: {
      toogleTopic () {
        this.$store.commit('chat/sendbox/isTopic', !this.isTopic)
      },
      toogleEmoji () {
        this.$store.commit('chat/sendbox/isEmoji', !this.isEmoji)
      },
      setTemplateMessage (messageText) {
        this.$store.commit(
          'chat/conversation/setCurrentConversationMessage',
          messageText,
        )
      },
      clickAttachIcon () {
        this.$refs.attachedFile.click()
      },
      changeAttachFiles () {
        this.$emit('attach-files', this.$refs.attachedFile.files)
        this.$refs.attachedForm.reset()
      },
      cancelRecorder () {
        this.isPlay = false
        this.isStop = false
        this.recordPlay = {}
        this.recordList = []
      },
      closeEmoji (e) {
        let isCloseEmoji = true
        e.path.forEach(el => {
          if (
            typeof el.classList !== 'undefined' &&
            el.classList.contains('emoji-block')
          ) {
            isCloseEmoji = false
          }
        })
        if (isCloseEmoji) {
          this.$store.commit('chat/sendbox/isEmoji', false)
        }
      },
    },
  }
</script>
