<template>
  <div class="attachment-deleted">
    <p class="body-l-regular neutral-900--text mb-0">
      Вложение удалено
    </p>
  </div>
</template>

<script>
  export default {
    data () {
      return {

      }
    },
  }
</script>
