export default {
  methods: {
    toBottomFeed () {
      const conversationFieldWrapper = this.$refs?.conversationFieldWrapper
      if (conversationFieldWrapper) conversationFieldWrapper.scrollTop = conversationFieldWrapper.scrollHeight
    },
    async updateMessages () {
      // обновление поиска по строке
      // массовое прочтение сообщений
      await this.$store.dispatch(
        'chat/message/toRead',
        this.currentConversationId,
      )
    },
    async fetchData (id) {
      // id чата
      if (id) {
        // асинхронная загрузка
        if (Object.keys(this.messages).length) {
          console.log('асинхронная загрузка')
          this._loadData(id)
        } else { // синхронная загрузка
          console.log('синхронная загрузка')
          this.loadingMessage = true
          await this._loadData(id)
        }
      }
    },
    async _loadData (id) {
       try {
        await this.$store.dispatch('chat/topic/list', id)
        await this.$store.dispatch('chat/group/list', id)

        const conversation = {
          id: id,
          offset: 0,
          limit: 20,
        }

        await this.$store.dispatch('chat/message/list', conversation)
      } catch (e) {
        console.error(e)
      } finally {
        this.loadingMessage = false
      }
    },
    async scrollFeed (e) {
      // загрузка страницы сообщений
      if (e.target.scrollTop === 0) {
        this.loadingMessagePage = true

        const keys = Object.keys(this.messages)
        if (keys.length) {
          this.messageIdToScrollPage = `message-${keys[0]}`
        }

        try {
          const conversation = {
            id: this.currentConversationId,
            offset: 0,
            limit: 20,
          }

          if (this.messages && Object.keys(this.messages).length) {
            conversation.offset = Object.keys(this.messages).length
          }

          await this.$store.dispatch('chat/message/list', conversation)
        } finally {
          this.loadingMessagePage = false
          // Обновление поиска по сообщений, обновление происходит если поиск включен
          this.searchByFilterString()
        }
      }
    },
    connectingMessages (messages) {
      if (this.$config.chat.messages.isCountGroup) {
        return this._countGroupMsg(messages)
      } else if (this.$config.chat.messages.isTimeGroup) {
        return this._timeGroupMsg(messages)
      }
    },
    _countGroupMsg (messages) {
      const connectMessageCount = this.$config.chat.messages.maxCountGroup // макс. кол-во сообщ. для соеденения
      const msgKeys = Object.keys(messages) // массив ключей объекта messages
      let checked = 0 // для подсчета кол-во сообщений подряд от одного пользователя
      for (const id in messages) {
        const msgIdx = msgKeys.indexOf(id) // порядковый индекс
        const maxConcatCount = (msgIdx + connectMessageCount) // макс. кол-во сообщений для перебора

        if (msgIdx === msgKeys.length - 1) {
          messages[msgKeys[msgIdx]].connectNextMessage = false
        } else {
          for (let i = msgIdx; i < maxConcatCount; i++) {
            if (msgKeys[i]) {
              const message = messages[msgKeys[i]]
              if (
                (
                  msgKeys[i + 1] &&
                  message.sender_id === messages[msgKeys[i + 1]].sender_id &&
                  message.real_sender_id === messages[msgKeys[i + 1]].real_sender_id
                ) && // если следующие сообщ. от проверяемого пользователя
                checked < connectMessageCount && // прошлый проверки от этого пользователя меньше чем #(connectMessageCount) раз
                (
                  !message.attachments.length ||
                  (
                    message.attachments.length &&
                    message.attachments[0].type !== 'media/audio' &&
                    message.attachments[0].type !== 'media/video' &&
                    message.attachments[0].type !== 'media/file' &&
                    message.attachments[0].type !== 'media/image'
                  )
                ) // если это не медиа файл
              ) {
                checked++
                message.connectNextMessage = true
              } else {
                checked = 0
                message.connectNextMessage = false
              }
            } else {
              break
            }
          }
        }
      }
      return messages
    },
    _timeGroupMsg (messages) {
      const msgKeys = Object.keys(messages) // массив ключей объекта messages
      for (const id in messages) {
        const msgIdx = msgKeys.indexOf(id) // порядковый индекс
        const maxConcatCount = (msgIdx + msgKeys.length - 1) // макс. кол-во сообщений для перебора

        if (msgIdx === msgKeys.length - 1) {
          messages[msgKeys[msgIdx]].connectNextMessage = false
        } else {
          for (let i = msgIdx; i < maxConcatCount; i++) {
            if (msgKeys[i]) {
              const message = messages[msgKeys[i]]
              if (
                (
                  msgKeys[i + 1] &&
                  message.sender_id === messages[msgKeys[i + 1]].sender_id &&
                  message.real_sender_id === messages[msgKeys[i + 1]].real_sender_id
                ) &&
                this._getDiffTimeWithTime(messages[msgKeys[i + 1]].created_at, message.created_at, 'seconds') <= this.$config.chat.messages.timeGroup
              ) {
                message.connectNextMessage = true
              } else {
                message.connectNextMessage = false
              }
            } else {
              break
            }
          }
        }
      }
      return messages
    },
    _getDiffTimeWithTime (date1, date2, wherein) {
      const time1 = new Date(date1).getTime()
      const time2 = new Date(date2).getTime()
      const momentTime1 = this.$moment(time1).local()
      const momentTime2 = this.$moment(time2).local()
      return momentTime1.diff(momentTime2, wherein)
    },
  },
}
