<template>
  <base-side-panel
    :active="innerActiveSidePanel"
    :static-back-button="true"
    class="side-panel__chat-add-member"
    :width="483"
    @back="closePanel"
  >
    <div class="chat-add-member__header">
      <p class="title-m-bold neutral-900--text">
        Добавить участника
      </p>
      <base-text-field
        v-model="search"
        class="field-search"
        placeholder="Поиск участников"
        prepend-inner-icon="$iconify_ion-search-outline"
        clear-icon="$iconify_ion-close-circle-outline"
        :prepend-inner-icon-color="this.$vuetify.theme.themes.light['neutral-500']"
        clearable
        hide-details
      />
    </div>
    <div
      class="chat-add-member__content"
      :style="clientsFiltered.length ? choosenClients.length ? 'height: calc(100% - 317px)' : 'height: calc(100% - 232px)' : ''"
    >
      <div
        v-if="clientsFiltered.length"
        class="clients"
      >
        <ul class="clients__list">
          <li
            v-for="client in clientsFiltered"
            :key="client.id"
            class="client__item"
            :class="{active: isChooseClient(client)}"
            @click="toogleChooseClient(client)"
          >
            <div class="right-block">
              <status-avatar-widget
                style="margin-right: 12px;"
                :src="client.avatar"
                :activity="client.last_activity"
              />
              <div class="item-name">
                <p class="body-m-medium neutral-900--text">
                  {{ client.name ? client.name : '-' }} {{ client.lastname ? client.lastname : '' }}
                </p>
              </div>
            </div>
            <div class="left-block">
              <iconify-icon
                v-if="isChooseClient(client)"
                class="icon-check"
                icon="octicon-check-circle-fill-16"
                width="21"
              />
            </div>
          </li>
        </ul>
      </div>
      <div
        v-else
        class="empty"
      >
        <base-empty
          text="Клиенты не найдены :’("
          sub-text="Скорее всего, вы добавили всех доступных клиентов"
        />
      </div>
    </div>
    <div
      v-if="choosenClients.length"
      class="chat-add-member__footer"
    >
      <v-btn
        color="primary"
        class="btn-create"
        :loading="loadingSave"
        @click="clickSave"
      >
        <iconify-icon
          class="icon"
          icon="ion-checkmark-circle-outline"
          width="21"
        />
        <p class="body-m-semibold neutral-100--text">
          Сохранить
        </p>
      </v-btn>
    </div>
  </base-side-panel>
</template>

<script>
  // mixins
  import MixinIndex from '@/views/communications/mixins/index'
  // components
  import StatusAvatarWidget from '@/views/communications/widgets/StatusAvatarWidget'
  // other
  import { mapGetters } from 'vuex'
  import FuzzySearch from 'fuzzy-search'

  export default {
    components: {
      StatusAvatarWidget,
    },
    mixins: [MixinIndex],
    model: {
      prop: 'activeSidePanel',
      event: 'changeSidePanel',
    },
    props: {
      activeSidePanel: Boolean,
    },
    data () {
      return {
        search: '',
        choosenClients: [],
        innerActiveSidePanel: this.activeSidePanel,
        loadingSave: false,
      }
    },
    computed: {
      ...mapGetters('company/program', ['programId']),
      conversationId () {
        return this.$store.getters['chat/conversation/currentConversationId']
      },
      conversation () {
        return this.$store.getters['chat/data/conversation'](this.conversationId)
      },
      chatUser () {
        return this.$store.getters['chat/chatUser/chatUser']
      },
      members () {
        return this.$store.getters['chat/data/members'](this.conversationId).filter(m => m.active && m.id !== this.chatUser.id)
      },
      clients () {
        return this.$store.getters['chat/member/clients']
      },
      clientsSearcher () {
        return new FuzzySearch(
          this.clients,
          ['name', 'lastname'],
          {
            caseSensitive: false,
          },
        )
      },
      clientsFiltered () {
        const memberIds = this.members.map(m => m.id)
        let clients = []

        if (this.clients.length) {
          clients = this.clients.filter(c => !memberIds.includes(c.id))
        }
        const search = String(this.search).replace(/\s+/g, ' ').replace(/^\s/g, '')

        if (clients.length && this.search && search.length) {
          clients = this.clientsSearcher.search(search.toLowerCase())

          this.setChoosenClients(
            this.choosenClients.filter(choosenClient => {
              const clientIds = clients.map(c => c.id)
              return clientIds.includes(choosenClient.id)
            }),
          )
        }

        return clients
      },
    },
    watch: {
      innerActiveSidePanel (v) {
        this.$emit('changeSidePanel', v)
        if (!v) {
          this.search = ''
          this.choosenClients = []
        }
      },
      activeSidePanel (v) {
        this.innerActiveSidePanel = v
      },
    },
    mounted () {
      this.search = ''
      this.choosenClients = []
    },
    methods: {
      closePanel () {
        this.innerActiveSidePanel = false
        this.$store.commit('chat/sidePanel/showChatEditPanel', true)
      },
      isChooseClient (client) {
        return this.choosenClients.findIndex(c => c.id === client.id) !== -1
      },
      toogleChooseClient (client) {
        const choosenClientIdx = this.choosenClients.findIndex(c => c.id === client.id)

        if (choosenClientIdx === -1) {
          this.choosenClients.push(client)
        } else {
          this.choosenClients.splice(choosenClientIdx, 1)
        }
      },
      setChoosenClients (payload) {
        this.choosenClients = payload
      },
      clickSave () {
        const data = {
          conversation_id: this.conversationId,
          members: this.choosenClients.map(item => item.id),
        }

        this.loadingSave = true
        this.$store.dispatch('chat/member/add', data).then((res) => {
          this.closePanel()
        }).finally(() => {
          this.loadingSave = false
        })
      },
    },
  }
</script>
