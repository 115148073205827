<template>
  <div
    class="app-conversation--sendForm__message__record-stop"
  >
    <div class="right-block">
      <iconify-icon
        v-if="!isPlay"
        class="icon icon-play"
        icon="feather-play"
        width="21"
        @click="switchPlayBackRecording"
      />
      <iconify-icon
        v-else
        class="icon icon-play"
        icon="ion-pause-outline"
        width="21"
        @click="switchPlayBackRecording"
      />
      <div class="strips">
        <div
          v-for="(v, i) in recordVolumes"
          :key="i"
          class="strip"
          :class="[isRecordStripActive(i) ? 'active' : '']"
          :style="`height: ${v}px`"
        />
      </div>
      <div class="durations">
        <p class="body-m-regular neutral-600--text">
          {{ formatTime(currentPlayTime) }}/{{ formatTime(recordList[0].millisecondsDuration * 1000) }}
        </p>
      </div>
    </div>
    <div class="left-block">
      <iconify-icon
        class="icon icon-trash"
        icon="feather-trash"
        width="21"
        @click="cancelRecorder"
      />
      <v-btn
        class="btn-send"
        color="neutral-500"
        text
        :loading="isSend"
      >
        <iconify-icon
          class="icon icon-send"
          icon="feather-send"
          width="21"
          @click="sendRecord"
        />
      </v-btn>
    </div>
  </div>
</template>

<script>
  import { filterAudioData, normalizeAudioData } from '@/utils/audio'

  export default {
    props: {
      isRecording: Boolean,
      isPlay: Boolean,
      isStop: Boolean,
      trackWaveCount: {
        type: [Number, String],
        default: '',
      },
      trackWaveMinHeight: {
        type: [Number, String],
        default: '',
      },
      recordList: {
        type: Array,
        default () {
          return []
        },
      },
      playCurrentTime: {
        type: [Number, String],
        default: '',
      },
      recordPlay: {
        type: [Object, HTMLAudioElement],
        default: new window.Audio(),
      },
      recorder: {
        type: Object,
        default: function () {
          return {}
        },
      },
    },
    data () {
      return {
        isAudioLoaded: false,
        isAudioLoading: false,
        audioCtx: new (window.AudioContext || window.webkitAudioContext)(),
        audioSource: {}, // initAudioData
        recordVolumes: [],
      }
    },
    computed: {
      currentPlayTime () {
        return (this.playCurrentTime * 1000) > (this.recordList[0].millisecondsDuration * 1000)
          ? (this.recordList[0].millisecondsDuration * 1000)
          : (this.playCurrentTime * 1000)
      },
      isSend () {
        return this.$store.getters['chat/sendbox/isSend']
      },
    },
    watch: {
      isAudioLoaded (v) {
        if (v) {
          if (this.audioStripInterval) {
            clearInterval(this.audioStripInterval)
          }
          this.loadVolumes()
        }
      },
    },
    mounted () {
      this.eventRecordPlayEnded()
      this.eventRecordPlayTimeUpdate()
      this.initAudioData()
    },
    methods: {
      isRecordStripActive (idx) {
        const duration = (this.recordPlay.duration * 1000)
        const part = (duration / this.trackWaveCount)
        return (part * idx) <= (this.playCurrentTime * 1000)
      },
      switchPlayBackRecording () {
        const isPlay = this.isPlay
        this.$emit('update:isPlay', !isPlay)
        if (isPlay) {
          this.recordPlay.pause()
        } else {
          this.recordPlay.play()
        }
      },
      async sendRecord () {
        if (this.isSend) return

        this.$store.commit('chat/sendbox/isSend', true)
        await this.$store.dispatch('chat/sendbox/sendRecord', this.recordList[0].blob)
          .finally(() => {
            this.$store.commit('chat/sendbox/isSend', false)
            this.cancelRecorder()
            this.$store.dispatch('chat/sendbox/clearForm')
            this.$emit('messageSent')
          })
      },
      cancelRecorder () {
        this.recordPlay.pause()
        this.$emit('update:isPlay', false)
        this.$emit('update:isStop', false)
        this.$emit('update:recordPlay', {})
        this.$emit('update:recordList', [])
      },
      loadVolumes () {
        const rawData = this.audioSource.buffer.getChannelData(0)
        const filteredData = filterAudioData(rawData, this.trackWaveCount)
        const normalizeData = normalizeAudioData(filteredData)
        this.recordVolumes = normalizeData.map(v => {
          const reduced = Math.round(v * 21)
          return (reduced <= this.trackWaveMinHeight)
            ? this.trackWaveMinHeight
            : reduced
        })
      },
      initAudioData () {
        this.audioSource = this.audioCtx.createBufferSource()
        this.isAudioLoading = true
        this.recordList[0].blob.arrayBuffer()
          .then(buffer => {
            return this.audioCtx.decodeAudioData(buffer)
          })
          .then(buffer => {
            this.isAudioLoaded = true
            this.audioSource.buffer = buffer
            this.audioSource.connect(this.audioCtx.destination)
          })
          .finally(() => {
            this.isAudioLoading = false
          })
      },
      formatTime (time) {
        return this.$moment(time).format('mm:ss')
      },
      eventRecordPlayEnded () {
        this.recordPlay.onended = () => {
          if (this.isPlay) this.$emit('update:isPlay', false)
        }
      },
      eventRecordPlayTimeUpdate () {
        this.recordPlay.ontimeupdate = () => {
          this.$emit('update:playCurrentTime', this.recordPlay.currentTime)
        }
      },
    },
  }
</script>
