<template>
  <div
    class="avatar-from-online"
    :class="customClass.length ? customClass : ''"
  >
    <img
      :src="src"
      class="avatar-img"
      @error="e => e.target.src = img404"
    >
    <div
      v-if="activity && isOnline(activity)"
      class="online"
    />
  </div>
</template>

<script>
  // mixins
  import MixinIndex from '@/views/communications/mixins/index'

  export default {
    mixins: [
      MixinIndex,
    ],
    props: {
      src: {
        type: String,
        default: '',
      },
      customClass: {
        type: String,
        default: '',
      },
      activity: {
        type: [Date, String],
        default: '',
      },
    },
  }
</script>
