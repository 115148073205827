export default {
  computed: {
    messages () {
      return this.$store.getters['chat/message/messages'][
        this.conversationId
      ]
    },
    loadingRequest () {
      return this.$store.getters['template/shared/loadingRequest']
    },
    loadingSend () {
      return this.$store.getters['chat/message/loading']
    },
    profile () {
      return this.$store.getters.user
    },
    programId () {
      return this.$store.getters['brand/program/programId']
    },
    chatUser () {
      return this.$store.getters['chat/chatUser/chatUser']
    },
    recipients () {
      return this.$store.getters['chat/message/recipients']
    },
    conversation () {
      return this.$store.getters['chat/data/conversation'](this.conversationId)
    },
    conversationId () {
      return this.$store.getters['chat/conversation/currentConversationId']
    },
    realChatName () {
      return this.$store.getters['chat/data/realChatName'](this.conversationId)
    },
    // sendbox
    fileForwarding () {
      return this.$store.getters['chat/sendbox/fileForwarding']
    },
    isSend () {
      return this.$store.getters['chat/sendbox/isSend']
    },
    isReply: {
      get () {
        return this.$store.getters['chat/sendbox/isReply']
      },
      set (v) {
        this.$store.commit('chat/sendbox/isReply', v)
      },
    },
    isEdit: {
      get () {
        return this.$store.getters['chat/sendbox/isEdit']
      },
      set (v) {
        this.$store.commit('chat/sendbox/isEdit', v)
      },
    },
    isChoice: {
      get () {
        return this.$store.getters['chat/sendbox/isChoice']
      },
      set (v) {
        this.$store.commit('chat/sendbox/isChoice', v)
      },
    },
    isEmoji: {
      get () {
        return this.$store.getters['chat/sendbox/isEmoji']
      },
      set (v) {
        this.$store.commit('chat/sendbox/isEmoji', v)
      },
    },
    isTopic: {
      get () {
        return this.$store.getters['chat/sendbox/isTopic']
      },
      set (v) {
        this.$store.commit('chat/sendbox/isTopic', v)
      },
    },
    replyMessageId: {
      get () {
        return this.$store.getters['chat/sendbox/replyMessageId']
      },
      set (v) {
        this.$store.commit('chat/sendbox/replyMessageId', v)
      },
    },
    editMessageId: {
      get () {
        return this.$store.getters['chat/sendbox/editMessageId']
      },
      set (v) {
        this.$store.commit('chat/sendbox/editMessageId', v)
      },
    },
    topicId: {
      get () {
        return this.$store.getters['chat/sendbox/topicId']
      },
      set (v) {
        this.$store.commit('chat/sendbox/topicId', v)
      },
    },
    choiceMessageIds: {
      get () {
        return this.$store.getters['chat/sendbox/choiceMessageIds']
      },
      set (v) {
        this.$store.commit('chat/sendbox/choiceMessageIds', v)
      },
    },
    messageText: {
      get () {
        return this.$store.getters['chat/sendbox/messageText']
      },
      set (v) {
        this.$store.commit('chat/sendbox/messageText', v)
      },
    },
  },
}
