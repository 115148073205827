import MixinData from './data.js'

export default {
  mixins: [MixinData],
  methods: {
    generateColorByNumber (val) {
      return this.colors[+([...val.toString()].pop())]
    },
    formatMessage (message) {
      if (message) {
        message = message.replace(/<<*\s*(\w+)\s*>*>/gm, '$1')
        let str = message
        let pos = 0
        while (true) {
          const foundPos = str.indexOf('\n', pos)
          if (foundPos !== -1) str = str.replace('\n', '<br>')
          if (foundPos === -1) break
          pos = foundPos
        }
        const regex = /(http|https):\/\/([\S]+)/gm
        const matchString = str.match(regex)
        if (matchString && matchString[0]) {
          const link =
            '<a target="_blank" href="' +
            matchString[0] +
            '">' +
            matchString[0] +
            '</a> '
          str = str.replace(matchString[0], link)
        }
        return str
      }
      return null
    },
    getShortMessage (msgItem) {
      const message = msgItem
      if (message) {
        if (message && message.attachments.length) {
          if (message.attachments[0].type === 'message/text') {
            return message.attachments[0].content
          }
          if (message.attachments[0].type === 'plus/account') return 'карта'
          if (message.attachments[0].type === 'media/image') {
            return 'изображение'
          }
          if (message.attachments[0].type === 'media/audio') {
            return 'аудиосообщение'
          }
          if (message.attachments[0].type === 'media/video') return 'видео'
          if (message.attachments[0].type === 'media/file') return 'файл'
        }
        return message.message
      }
      return ''
    },
    formatStatus (status) {
      if (status === 'sending') return 'Отправлено'
      if (status === 'delivered') return 'Доставлено'
      if (status === 'seen') return 'Просмотрено'
      return status
    },
    removeCarry (text) {
      if (!text || !text.length) return text
      text = text.replace(/<\s*br\s*>/gmi, ' ')
      text = text.replace(/\s*(?=(\n|\r|\r\n))/gmi, '')
      return text
    },
    isEmptyObject (obj) {
      return JSON.stringify(obj) === '{}'
    },
    declOfNum (number, titles) {
      const cases = [2, 0, 1, 1, 1, 2]
      return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]]
    },
    toRoute (path) {
      if (this.$route.path !== path) this.$router.push(path)
    },
  },
}
