<template>
  <div class="attachment-video">
    <div
      class="video-thumb"
    >
      <img
        :src="content.thumb"
        alt="img"
      >
      <iconify-icon
        class="icon-play"
        icon="feather-play"
        width="21"
        @click="show = true"
      />
    </div>
    <preview-video
      :show.sync="show"
      :content="content"
    />
  </div>
</template>

<script>
  import PreviewVideo from './preview/PreviewVideo'

  export default {
    components: {
      PreviewVideo,
    },
    props: {
      content: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        show: false,
        download: false,
      }
    },
    computed: {
    },
    mounted () {
    },
  }
</script>
