var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"message-line",class:{
    select: _vm.showActions || (_vm.isChoiceMessage && _vm.isChoice),
    choice: _vm.isChoiceMessage,
    connectNextMessage: _vm.item.connectNextMessage,
  },on:{"click":_vm.choiceMessage}},[(_vm.isChoiceMessage)?_c('div',{staticClass:"choice__overlay"}):_vm._e(),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideActions),expression:"hideActions"}],staticClass:"message-block"},[_c('div',{ref:'messageAction' + _vm.item.id,staticClass:"message-actions",class:[
        _vm.showActions ? 'show' : 'hide',
        _vm.actionPlacement, {'message-my': _vm.myMessage} ]},[_c('ul',{staticClass:"action-list"},[_c('li',{staticClass:"action-item"},[_c('a',{attrs:{"href":"#"},on:{"click":_vm.openReplyMessage}},[_c('iconify-icon',{staticClass:"icon icon-undo",attrs:{"icon":"ion-arrow-undo-outline","width":"21"}}),_c('p',{staticClass:"body-s-medium neutral-500--text"},[_vm._v("Ответить")])],1)]),(_vm.item.attachments.length || _vm.item.message)?_c('li',{staticClass:"action-item"},[_c('a',{attrs:{"href":"#"},on:{"click":_vm.openForwardMessage}},[_c('iconify-icon',{staticClass:"icon icon-forward",attrs:{"icon":"ion-arrow-undo-outline","width":"21"}}),_c('p',{staticClass:"body-s-medium neutral-500--text"},[_vm._v("Переслать")])],1)]):_vm._e(),_c('li',{staticClass:"action-item"},[_c('a',{attrs:{"href":"#"},on:{"click":_vm.copyMessage}},[_c('iconify-icon',{staticClass:"icon icon-copy",attrs:{"icon":"feather-copy","width":"21"}}),_c('p',{staticClass:"body-s-medium neutral-500--text"},[_vm._v("Копировать")])],1)]),(_vm.myMessage &&
            (_vm.item.message && _vm.item.message.length))?_c('li',{staticClass:"action-item"},[_c('a',{attrs:{"href":"#"},on:{"click":_vm.openEditMessage}},[_c('iconify-icon',{staticClass:"icon icon-edit",attrs:{"icon":"feather-edit","width":"21"}}),_c('p',{staticClass:"body-s-medium neutral-500--text"},[_vm._v("Редактировать")])],1)]):_vm._e(),(_vm.myMessage)?_c('li',{staticClass:"action-item"},[_c('a',{attrs:{"href":"#"},on:{"click":_vm.openDeleteMessage}},[_c('iconify-icon',{staticClass:"icon icon-trash",attrs:{"icon":"feather-trash","width":"21"}}),_c('p',{staticClass:"body-s-medium neutral-500--text"},[_vm._v("Удалить")])],1)]):_vm._e()])]),(!_vm.item.connectNextMessage)?_c('img',{staticClass:"message-author-avatar",attrs:{"src":_vm.getAuthorAvatar(_vm.item, _vm.payload)},on:{"error":function (e) { return e.target.src = _vm.img404; }}}):_vm._e(),_c('div',{on:{"contextmenu":function($event){$event.preventDefault();_vm.showActions = true}}},[_c('div',{class:{
          'message-box': true,
          'message-my': _vm.myMessage,
        }},[(_vm.item.topic_name)?_c('div',{staticClass:"message-topic"},[_c('p',{staticClass:"body-x-semibold primary--text"},[_vm._v(" #"+_vm._s(_vm.item.topic_name)+" ")])]):_vm._e(),(_vm.showAuthorName)?_c('div',{staticClass:"message-box-author-name"},[_c('p',{staticClass:"body-s-semibold neutral-900--text mb-0"},[_vm._v(" "+_vm._s(_vm.getAuthorName(_vm.item, _vm.payload))+" ")])]):_vm._e(),(_vm.item.parent_id &&
            _vm.item.parent_message.conversation_id !== _vm.conversation.id)?_c('div',{staticClass:"message-box-forward-text"},[_c('p',{staticClass:"body-s-medium neutral-500--text mb-0"},[_vm._v(" Пересланное сообщение ")])]):_vm._e(),(_vm.item.parent_id)?_c('div',{staticClass:"message-box-quote",style:(_vm.item.message && _vm.item.message.length ? 'margin-bottom: 12px;' : '')},[(_vm.item.parent_message.attachments.length)?_c('app-attachments',{attrs:{"msg-item":_vm.item,"attachments":_vm.item.parent_message.attachments,"conversation-id":_vm.conversationId}}):_vm._e(),(_vm.item.parent_message.message)?_c('div',{staticClass:"quote"},[_c('div',{staticClass:"quote-author"},[_c('p',{staticClass:"body-s-semibold neutral-700--text mb-0"},[_vm._v(" "+_vm._s(_vm.item.parent_message.sender.name)+" ")])]),_c('div',{staticClass:"quote-text"},[_c('p',{staticClass:"body-s-regular neutral-700--text mb-0",domProps:{"innerHTML":_vm._s(_vm._prepareMessageText(_vm.item.parent_message.message))}})])]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"message-box-message",style:(_vm.item.attachments.length ? 'margin-right: 0px' : '')},[(_vm.item.attachments.length)?_c('div',{staticClass:"message-box-message-attachment"},[_c('app-attachments',{attrs:{"msg-item":_vm.item,"attachments":_vm.item.attachments,"conversation-id":_vm.conversationId}})],1):_vm._e(),(_vm.item.message)?_c('div',{staticClass:"message-box-text"},[_c('p',{staticClass:"body-s-regular neutral-900--text mb-0",domProps:{"innerHTML":_vm._s(_vm._prepareMessageText(_vm.item.message))}})]):_vm._e()]),(_vm.myMessage &&
            (_vm.item.message && _vm.item.message.length))?_c('div',{staticClass:"message-box-edit"},[_c('iconify-icon',{staticClass:"icon-edit",attrs:{"icon":"feather-edit-2","width":"15"},on:{"click":_vm.openEditMessage}})],1):_vm._e()])])]),_c('div',{staticClass:"message__right-block"},[(_vm.myMessage)?_c('div',{staticClass:"message-shipment"},[(!_vm.item.read)?_c('iconify-icon',{staticClass:"icon icon-check",attrs:{"icon":"feather-check","width":"21"}}):_vm._e(),(_vm.item.read)?_c('iconify-icon',{staticClass:"icon icon-done",attrs:{"icon":"ion-checkmark-done","width":"21"}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"message-time"},[_c('p',{staticClass:"body-s-regular neutral-500--text mb-0"},[_vm._v(" "+_vm._s(_vm.getDate(_vm.item.created_at))+" ")])]),(_vm.isChoiceMessage)?_c('div',{staticClass:"message-choice"},[(_vm.isChoice)?_c('iconify-icon',{staticClass:"icon-check",attrs:{"icon":"octicon-check-circle-fill-16","width":"21"}}):_c('iconify-icon',{staticClass:"icon-not-check",attrs:{"icon":"feather-circle","width":"21"}})],1):_vm._e()]),(_vm.isDelete)?_c('app-message-delete',{attrs:{"is-delete":_vm.isDelete,"msg-item":_vm.editedItem,"show-delete-all":_vm.myMessage},on:{"update:isDelete":function($event){_vm.isDelete=$event},"update:is-delete":function($event){_vm.isDelete=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }