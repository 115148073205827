<template>
  <div
    class="group-topic"
  >
    <div class="group-topic__header">
      <p class="title-m-bold neutral-900--text">
        Редактирование темы
      </p>
    </div>
    <div
      class="group-topic__content"
    >
      <div class="topic__fields">
        <div class="topic-field">
          <p class="topic-name body-l-semibold neutral-900--text">
            Название темы
          </p>
          <p class="topic-description body-m-regular-neutral-700--text">
            Придумывайте осмысленные навазния для тем, чтобы участники чата понимали о чем идет речь.
          </p>
          <base-text-field
            v-model="topicName"
            class="topic-field"
            placeholder="Введите название темы"
            hide-details
          />
        </div>
        <div class="topic-field">
          <p class="body-l-semibold neutral-900--text">
            Участники
          </p>
          <p class="body-m-regular-neutral-700--text">
            Все участники чата видят сообщения с этой темой. Вы можете включать и выключать возможность писать сообщения с этой темой отдельным участникам.
          </p>
          <ul
            ref="memberListEdit"
            class="members__list"
          >
            <li
              v-for="member in assortedSelectedMembers"
              :key="member.id"
              class="member__item"
            >
              <div class="left-block">
                <status-avatar-widget
                  :src="member.avatar"
                  :activity="member.last_activity"
                />
                <p class="name body-m-medium neutral-900--text">
                  {{ member.name }}
                </p>
              </div>
              <div
                v-if="topic && member.id !== topic.owner_id"
                class="right-block"
              >
                <base-ext-switch
                  v-model="membersCanWrite[member.id]"
                  class="status"
                  :label="membersCanWrite[member.id] ? 'Пишит' : 'Читает'"
                />
                <v-btn
                  class="btn__delete-member"
                  text
                  @click="deleteMember(member.id)"
                >
                  <iconify-icon
                    class="icon-close neutral-500--text"
                    icon="ion-close"
                    width="21"
                  />
                </v-btn>
              </div>
            </li>
          </ul>
          <v-btn
            class="members__btn-create"
            text
            @click="$emit('add-member')"
          >
            <iconify-icon
              class="icon-plus primary-base--text"
              icon="feather-plus-circle"
              width="21"
            />
            <p class="body-s-semibold primary-base--text mb-0">
              Добавить участника
            </p>
          </v-btn>
        </div>
      </div>
    </div>
    <div
      class="group-topic__footer"
    >
      <div class="topic__controls">
        <v-btn
          class="save__btn"
          color="primary"
          :loading="isRequestUpdate"
          @click="updateTopic()"
        >
          <iconify-icon
            icon="ion-checkmark-circle-outline"
            class="icon icon-check"
            width="21"
          />
          <p class="body-m-semibold neutral-100--text">
            Сохранить
          </p>
        </v-btn>
        <v-btn
          class="delete__btn"
          color="error"
          :loading="isRequestDelete"
          text
          @click="deleteTopic()"
        >
          <iconify-icon
            icon="feather-trash"
            class="icon icon-check error--text"
            width="21"
          />
          <p class="body-m-semibold  error--text">
            Удалить тему
          </p>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
  // mixins
  import MixinIndex from '@/views/communications/mixins/index.js'
  // components
  import StatusAvatarWidget from '@/views/communications/widgets/StatusAvatarWidget'

  export default {
    components: {
      StatusAvatarWidget,
    },
    mixins: [
      MixinIndex,
    ],
    props: {
      show: Boolean,
      selectedMembers: {
        type: Array,
        default () {
          return []
        },
      },
      editTopicId: {
        type: [Number, String],
        default: NaN,
      },
    },
    data () {
      return {
        topicName: '',
        membersCanWrite: {},
        isRequestUpdate: false,
        isRequestDelete: false,
      }
    },
    computed: {
      conversationId () {
        return this.$store.getters['chat/conversation/currentConversationId']
      },
      topics () {
        return this.$store.getters['chat/topic/topics']
      },
      topic () {
        return this.topics.find(t => t.id === this.editTopicId)
      },
      members () {
        let members = this.$store.getters['chat/data/members'](this.conversationId).filter(m => m.active)
        if (this.editTopicId && !isNaN(this.editTopicId)) {
          if (this.topic && this.topic.members.length) {
            const memberIds = this.topic.members.map(m => m.id)
            members = members.filter(m => {
              if (memberIds.includes(m.id)) return true
              return false
            })
          }
        }
        return members
      },
      myMember () {
        if (this.editTopicId && !isNaN(this.editTopicId) && this.topic) {
          return this.members.find(m => m.id === this.topic.owner_id)
        }
        return false
      },
      assortedSelectedMembers () {
        let assorted = Object.assign([], this.selectedMembers)
        if (this.myMember) {
          assorted = assorted.filter(m => m.id !== this.topic.owner_id)
          assorted.unshift(this.myMember)
        }
        return assorted
      },
    },
    watch: {
      editTopicId (v) {
        if (!isNaN(v)) {
          this.init()
        }
      },
      show (v) {
        if (v && !isNaN(this.editTopicId)) {
          this.init()
        }
      },
    },
    mounted () {
      this.$nextTick(() => {
        window.addEventListener('resize', this.listMaxHeight)
      })
    },
    updated () {
      this.listMaxHeight()
    },
    methods: {
      listMaxHeight () {
        if (this.$refs?.memberListEdit) {
          this.$refs.memberListEdit.style.maxHeight = document.body.clientHeight - 584 + 'px'
        }
      },
      setSelectedMembers (payload) {
        this.$emit('update:selectedMembers', payload)
      },
      setMembersCanWrite (payload) {
        this.membersCanWrite = payload
      },
      updateTopic () {
        const topic = {
          id: this.editTopicId,
          name: this.topicName,
          is_private: false,
          members: this._prepareRequestMembers(),
        }

        this.isRequestUpdate = true
        this.$store.dispatch('chat/topic/update', topic).then(() => {
          this.$emit('update:selectedMembers', [])
          this.membersCanWrite = {}
          this.$emit('close')
        }).finally(() => {
          this.isRequestUpdate = false
        })
      },
      deleteMember (memberId) {
        this.$emit('update:selectedMembers', this.selectedMembers.filter(m => m.id !== memberId))
      },
      deleteTopic () {
        this.isRequestDelete = true
        this.$store.dispatch('chat/topic/delete', this.editTopicId).then(() => {
          this.$emit('close')
          this.$emit('update:selectedMembers', [])
          this.membersCanWrite = {}
        }).finally(() => {
          this.isRequestDelete = false
        })
      },
      init () {
        if (this.topic && Object.keys(this.topic).length && !this.selectedMembers.length) {
          this.topicName = this.topic.name
          this.setSelectedMembers(this.members)
          const canWrite = {}
          if (this.topic.members.length) {
            this.topic.members.forEach(m => {
              if (m.can_write) {
                canWrite[m.id] = m.can_write
              }
            })
          }
          this.setMembersCanWrite(canWrite)
        }
      },
      _prepareRequestMembers () {
        let members = Object.assign([], this.selectedMembers)

        members = members.map(m => {
          m.can_write = false
          return m
        })

        if (Object.keys(this.membersCanWrite).length) {
          for (const id in this.membersCanWrite) {
            const currMemberIdx = members.findIndex(m => Number(m.id) === Number(id))
            if (currMemberIdx !== -1) {
              members[currMemberIdx].can_write = this.membersCanWrite[id]
            }
          }
        }

        this.$emit('update:selectedMembers', members)

        return members
      },
    },
  }
</script>
