<template>
  <div class="user-topic">
    <div class="user-topic__header">
      <p class="title-m-bold neutral-900--text">
        Темы чата
      </p>
    </div>
    <div class="user-topic__content">
      <ul
        v-if="topics.length"
        class="topics__list"
      >
        <li
          v-for="topic in topics"
          :key="topic.id"
          v-click-outside="setEditableTopic.bind(null, [topic.id, false])"
          class="topic__item"
        >
          <base-text-field
            v-if="editableTopics[topic.id]"
            :value="topicEditableName"
            class="item-field"
            hide-details
            placeholder="Введите название темы"
          />
          <p
            v-else
            class="item-text body-m-semibold neutral-900--text"
            @dblclick="editTopic(topic.id)"
          >
            {{ topic.name }}
          </p>
          <div class="controls">
            <v-btn
              :loading="loadingsEdit[topic.id]"
              class="btn-edit d-flex"
              color="primary-100"
              @click="editTopic(topic.id)"
            >
              <iconify-icon
                icon="feather-edit-2"
                class="icon icon-edit primary-base--text"
                width="14"
              />
            </v-btn>
            <v-btn
              v-if="editableTopics[topic.id]"
              :loading="loadingsDelete[topic.id]"
              class="btn-delete d-flex"
              color="primary-100"
              style="background: rgba(234, 76, 42, 0.15);"
              @click="deleteTopic(topic.id)"
            >
              <iconify-icon
                icon="feather-trash"
                class="icon icon-close error--text"
                width="14"
              />
            </v-btn>
          </div>
        </li>
      </ul>
    </div>
    <div class="topic__create">
      <v-btn
        v-if="!isCreationTopic"
        class="btn-create"
        color="primary"
        :loading="loadingCreate"
        @click="isCreationTopic = true"
      >
        <iconify-icon
          icon="feather-plus-circle"
          class="icon-check"
          width="21"
        />
        <p class="body-m-semibold neutral-100--text">
          Новая тема
        </p>
      </v-btn>
      <base-text-field
        v-if="isCreationTopic"
        v-model="createTopicName"
        class="field-create"
        maxlength="45"
        placeholder="Введите название темы"
        @keypress.enter.exact="createTopic"
      />
      <v-btn
        v-if="isCreationTopic && createTopicName.replace(/\s+/, ' ').replace(/^\s/, '').length"
        class="btn-add"
        color="primary"
        :loading="loadingCreate"
        @click="createTopic"
      >
        <iconify-icon
          icon="ion-checkmark-circle-outline"
          class="icon-check"
          width="21"
        />
        <p class="body-m-semibold neutral-100--text">
          Добавить тему
        </p>
      </v-btn>
    </div>
  </div>
</template>

<script>
  import vClickOutside from 'v-click-outside'

  export default {
    directives: {
      clickOutside: vClickOutside.directive,
    },
    props: {
      clickBackBtn: Boolean,
      activeSidePanel: Boolean,
    },
    data () {
      return {
        createTopicName: '',
        isCreationTopic: false,
        topicEditableName: '',
        editableTopics: {},
        loadingsDelete: {},
        loadingsEdit: {},
        loadingCreate: false,
        callFromOutside: false,
      }
    },
    computed: {
      conversationId () {
        return this.$store.getters['chat/conversation/currentConversationId']
      },
      topics () {
        return this.$store.getters['chat/topic/topics']
      },
      profile () {
        return this.$store.getters.user
      },
      chatUser () {
        return this.$store.getters['chat/chatUser/chatUser']
      },
      members () {
        return this.$store.getters['chat/data/members'](this.conversationId)
      },
      nameExist () {
        return this.$store.getters['chat/topic/nameExist']
      },
      topicPanelModes () {
        return this.$store.getters['chat/sendbox/topicPanel']
      },
    },
    watch: {
      topicPanelModes: {
        deep: true,
        handler (v) {
          if (v.mode.length) {
            this.modesHandler()
          }
        },
      },
      clickBackBtn () {
        this.closePanel()
      },
      activeSidePanel (v) {
        if (!v) {
          this.isCreationTopic = false
          this.createTopicName = ''
          this.callFromOutside = false
        }
      },
    },
    methods: {
      async deleteTopic (topicId) {
        this.$set(this.loadingsDelete, topicId, true)
        await this.$store.dispatch('chat/topic/delete', topicId)
          .finally(() => {
            this.$set(this.loadingsDelete, topicId, false)
          })
      },
      setEditableTopic (topicId, bool = false) {
        if (Array.isArray(topicId)) [topicId, bool] = topicId
        this.$set(this.editableTopics, topicId, bool)
      },
      async editTopic (topicId) {
        if (!this.editableTopics[topicId]) {
          this.topicEditableName = this.topics.find(t => t.id === topicId).name
          this.setEditableTopic(topicId, true)
          return
        }

        const topic = {
          id: topicId,
          name: this.topicEditableName,
          is_private: false,
          members: this._prepareRequestMembers(),
        }

        this.$set(this.loadingsEdit, topicId, true)
        this.$store.dispatch('chat/topic/update', topic).then((r) => {
          this.$set(this.loadingsEdit, topicId, false)
          this.$set(this.editableTopics, topicId, false)
        })
      },
      modeEditTopic (topicId) {
        this.$set(this.editableTopics, topicId, true)
      },
      closePanel () {
        if (
          this.callFromOutside &&
          this.topicPanelModes?.call?.direction &&
          this.topicPanelModes.call.of === 'ChatEditUser'
        ) {
          const topicPanelModes = Object.assign({}, this.topicPanelModes)
          topicPanelModes.call.direction = 'reception'
          this.$store.commit('chat/sendbox/topicPanel', topicPanelModes)
          this.$emit('closePanel')
        } else {
          this.$emit('closePanel')
        }
      },
      async createTopic () {
        if (!this.createTopicName.replace(/\s+/, ' ').replace(/^\s/, '').length) return

        const topic = {
          conversation_id: this.conversationId,
          name: this.createTopicName,
          is_private: false,
          members: this._prepareRequestMembers(),
        }

        this.loadingCreate = true
        await this.$store.dispatch('chat/topic/create', topic)
          .finally(() => {
            this.loadingCreate = false
            this.createTopicName = ''
            this.isCreationTopic = false
          })
      },
      modesHandler () {
        this.callFromOutside = true
        const topicPanel = Object.assign({}, this.topicPanelModes)
        topicPanel.mode = ''
        this.$store.commit('chat/sendbox/topicPanel', topicPanel)
      },
      _prepareRequestMembers () {
        const members = []
        this.members.forEach(m => {
          members.push({
            id: m.id,
            can_write: true,
          })
        })
        return members
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
