<template>
  <base-side-panel
    v-model="innerActiveSidePanel"
    class="side-panel__chat-edit-group"
    :width="483"
  >
    <div class="chat-edit-group__header">
      <div class="header__title">
        <p class="title-text title-m-bold neutral-900--text mb-0">
          Информация о чате
        </p>
        <div
          class="title-mute"
          @click="setMuted(conversation)"
        >
          <v-btn
            class="btn-volume"
            color="neutral-500"
            :loading="loadingMuted"
            :ripple="false"
            text
          >
            <v-icon
              v-if="conversation.muted"
              class="icon-volume"
              color="neutral-500"
              size="21"
            >
              $icons_speaker-on
            </v-icon>
            <v-icon
              v-else
              class="icon-volume"
              color="neutral-500"
              size="21"
            >
              $icons_speaker-off
            </v-icon>
          </v-btn>
        </div>
      </div>
      <base-text-field
        v-model="groupName"
        class="header__field-group-name"
        placeholder="Имя группы"
        :rules="groupNameRules"
        :validation-placement="'bottom'"
        error-style="vuetify"
      />
    </div>
    <div class="chat-edit-group__content">
      <div class="content__members">
        <p class="members__title body-l-semibold neutral-900--text">
          {{ activeMembers.length + ' ' + declOfNum(activeMembers.length, declensionsMember) }}
        </p>
        <base-text-field
          v-model="searchMembers"
          class="members__field-search field-search"
          placeholder="Поиск"
          prepend-inner-icon="$iconify_ion-search-outline"
          clear-icon="$iconify_ion-close-circle-outline"
          :prepend-inner-icon-color="this.$vuetify.theme.themes.light['neutral-500']"
          clearable
          hide-details
        />
        <ul class="members__list">
          <li
            v-for="member in activeMembersFiltered"
            :key="member.id"
            class="members__item"
          >
            <div
              class="left-block"
              @click="openPanelClientInfo(member.id)"
            >
              <status-avatar-widget
                style="margin-right: 12px;"
                :src="member.avatar"
                :activity="member.last_activity"
              />
              <p class="member-name body-m-medium neutral-900--text mb-0">
                {{ member.name }}
              </p>
            </div>
            <v-btn
              color="neutral-500"
              class="btn-close"
              :loading="getDeleteMemberLoading(member.id)"
              text
              @click="clickDeleteMember(member.id)"
            >
              <iconify-icon
                class="icon-close neutral-500--text"
                icon="ion-close"
                width="21"
              />
            </v-btn>
          </li>
        </ul>
        <v-btn
          class="members__btn-create"
          text
          @click="openAddMemberPanel()"
        >
          <iconify-icon
            class="icon-plus primary-base--text"
            icon="feather-plus-circle"
            width="21"
          />
          <p class="body-s-semibold primary-base--text mb-0">
            Добавить участника
          </p>
        </v-btn>
      </div>
      <div class="content__topics">
        <p class="topics__title body-l-semibold neutral-900--text">
          {{ topics.length + ' ' + declOfNum(topics.length, declensionsTopic) }}
        </p>
        <ul
          v-if="topics.length"
          ref="topicList"
          class="topics__list"
        >
          <li
            v-for="topic in topics"
            :key="topic.id"
            class="topics__item"
          >
            <div class="left-block">
              <p class="item-name body-m-semibold neutral-900--text mb-0">
                {{ topic.name }}
              </p>
              <p class="item-writers body-s-medium neutral-500--text mb-0">
                {{ getTopicWritersText(topic.members) }}
              </p>
            </div>
            <v-btn
              class="topic__btn-edit"
              color="primary-100"
              @click="openTopicPanelEdit(topic.id)"
            >
              <iconify-icon
                class="icon-edit primary-base--text"
                icon="feather-edit-2"
                width="15"
              />
            </v-btn>
          </li>
        </ul>
        <v-btn
          class="topics__btn-create"
          color="primary"
          @click="openTopicPanelCreate()"
        >
          <iconify-icon
            class="icon-create neutral-100--text"
            icon="feather-plus-circle"
            width="21"
          />
          <p class="body-m-semibold neutral-100--text mb-0">
            Новая тема
          </p>
        </v-btn>
      </div>
      <media-widget />
    </div>
  </base-side-panel>
</template>

<script>
  // mixins
  import MixinIndex from '@/views/communications/mixins/index'
  // other
  import FuzzySearch from 'fuzzy-search'
  // components
  import MediaWidget from '@/views/communications/widgets/MediaWidget'
  import StatusAvatarWidget from '@/views/communications/widgets/StatusAvatarWidget'
  // other
  import ApiService from '@/api/api-client'

  export default {
    components: {
      MediaWidget,
      StatusAvatarWidget,
    },
    mixins: [MixinIndex],
    model: {
      prop: 'activeSidePanel',
      event: 'changeSidePanel',
    },
    props: {
      activeSidePanel: Boolean,
    },
    data () {
      return {
        innerActiveSidePanel: this.activeSidePanel,
        loadingMuted: false,
        loadingsDeleteMember: [],
        groupName: '',
        groupNameRules: [
          v => !!v || 'Имя группы обязателено',
          v => !/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gmi.test(v) || 'Указан недопустимый символ',
          v => (v && v.replace(/\s+/g, ' ').replace(/^\s/g, '').length >= 3) || 'Имя должно быть не менее 3 символов',
          v => (v && v.replace(/\s+/g, ' ').replace(/^\s/g, '').length <= 30) || 'Имя должно быть не более 50 символов',
        ],
        searchMembers: '',
      }
    },
    computed: {
      conversationId () {
        return this.$store.getters['chat/conversation/currentConversationId']
      },
      conversation () {
        return this.$store.getters['chat/data/conversation'](this.conversationId)
      },
      chatUser () {
        return this.$store.getters['chat/chatUser/chatUser']
      },
      members () {
        return this.$store.getters['chat/data/members'](this.conversationId)
      },
      topics () {
        return this.$store.getters['chat/topic/topics']
      },
      activeMembersFiltered () {
        let members = this.activeMembers
        const search = String(this.searchMembers).replace(/\s+/g, ' ').replace(/^\s/g, '')

        if (this.searchMembers && search.length) {
          members = this.activeMembersSearcher.search(search.toLowerCase())
        }

        return members
      },
      activeMembersSearcher () {
        return new FuzzySearch(
          this.activeMembers,
          ['name'],
          {
            caseSensitive: false,
          },
        )
      },
      program () {
        return this.$store.getters['company/program/program']
      },
      activeMembers () {
        return this.members.filter(m => m.active && m.id !== this.chatUser.id)
      },
      clients () {
        return this.$store.getters['chat/member/clients']
      },
      topicPanelModes () {
        return this.$store.getters['chat/sendbox/topicPanel']
      },
    },
    watch: {
      innerActiveSidePanel (v) {
        this.$emit('changeSidePanel', v)
      },
      activeSidePanel (v) {
        this.innerActiveSidePanel = v
        if (v) {
          this.groupName = this.conversation.display_name
        }
      },
      groupName (v) {
        const valid = !this.groupNameRules.filter(rule => rule(v) !== true).length
        if (valid && v !== this.conversation.display_name) {
          const data = {
            conversation_id: this.conversationId,
            name: v,
          }

          this.$store.dispatch('chat/conversation/update', data)
        }
      },
      topicPanelModes: {
        deep: true,
        handler (v) {
          if (v && Object.keys(v).includes('call')) {
            if (v.call.of === 'ChatEditGroup' && v.call.direction === 'reception') {
              this.innerActiveSidePanel = true
            }
          }
        },
      },
    },
    mounted () {
      this.$nextTick(() => {
        window.addEventListener('resize', this.listMaxHeight)
      })
    },
    updated () {
      this.listMaxHeight()
    },
    methods: {
      listMaxHeight () {
        if (this.$refs?.topicList) {
          this.$refs.topicList.style.maxHeight = document.body.clientHeight - 554 + 'px'
        }
      },
      setMuted (item) {
        const conversation = {
          conversation_id: item.id,
        }
        this.loadingMuted = true
        if (item.muted) {
          this.$store.dispatch('chat/conversation/mutedRemove', conversation)
            .finally(() => {
              this.loadingMuted = false
            })
        } else {
          this.$store.dispatch('chat/conversation/mutedSet', conversation)
            .finally(() => {
              this.loadingMuted = false
            })
        }
      },
      async openPanelClientInfo (memberId) {
        const result = await ApiService.get('/api-cabinet/widget/client', {
          params: {
            client_id: memberId,
            program_id: this.program.id,
          },
        })
        const data = result.client
        data.id = data.accounts[0].id
        this.$store.commit('chat/sidePanel/clientInfoPanel', { active: true, data: data })
      },
      openAddMemberPanel () {
        this.innerActiveSidePanel = false
        this.$emit('openAddMemberPanel')
      },
      getTopicWritersText (members) {
        let text = ''
        const membersWriters = members.filter(m => m.can_write && m.id !== this.chatUser.id)
        const membersReaders = members.filter(m => !m.can_write && m.id !== this.chatUser.id)

        if (membersWriters.length) {
          text += membersWriters.length + ' ' + this.declOfNum(membersWriters.length, this.declensionsWriter)
          if (membersReaders.length) text += ', '
        }
        if (membersReaders.length) {
          text += membersReaders.length + ' ' + this.declOfNum(membersReaders.length, this.declensionsReader)
        }
        return text
      },
      async clickDeleteMember (memberId) {
        let loadingIdx = this.loadingsDeleteMember.findIndex(l => l.id === memberId)

        if (loadingIdx === -1) {
          loadingIdx = this.loadingsDeleteMember.push({ id: memberId, loading: true }) - 1
        } else {
          return
        }

        const data = {
          conversation_id: this.conversationId,
          members: [memberId],
        }

        await this.$store.dispatch('chat/member/remove', data)
          .finally(() => {
            this.loadingsDeleteMember[loadingIdx].loading = false
          })
      },
      openTopicPanelCreate () {
        this.innerActiveSidePanel = false
        this.$store.commit('chat/sendbox/topicPanel', {
          mode: 'create',
          call: {
            of: 'ChatEditGroup',
            direction: 'recoil',
          },
        })
        this.$store.commit('chat/sendbox/isTopicPanel', true)
      },
      openTopicPanelEdit (topicId) {
        this.innerActiveSidePanel = false
        this.$store.commit('chat/sendbox/topicPanel', {
          mode: 'edit',
          editId: topicId,
          call: {
            of: 'ChatEditGroup',
            direction: 'recoil',
          },
        })
        this.$store.commit('chat/sendbox/isTopicPanel', true)
      },
      getDeleteMemberLoading (memberId) {
        const dl = this.loadingsDeleteMember.find(l => l.id === memberId)
        return dl ? dl.loading : false
      },
    },
  }
</script>
