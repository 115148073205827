<template>
  <div class="group-topic__add-member">
    <p
      v-if="title.length"
      class="add-member__title title-m-bold neutral-900--text"
    >
      {{ title }}
    </p>
    <div class="add-member__header">
      <p class="header-title body-l-semibold neutral-900--text">
        Добавление нового участника
      </p>
      <p class="header-description body-m-regular neutral-700--text">
        Воспользуйтесь поиском, чтобы найти и добавить нового
        участника
      </p>
      <base-text-field
        v-model="searchMembers"
        class="members__field-search field-search"
        placeholder="Поиск нового участника"
        prepend-inner-icon="$iconify_ion-search-outline"
        clear-icon="$iconify_ion-close-circle-outline"
        :prepend-inner-icon-color="this.$vuetify.theme.themes.light['neutral-500']"
        clearable
        hide-details
      />
    </div>
    <div class="add-member__content">
      <ul
        v-if="activeMembersFiltered.length"
        ref="addMemberList"
        class="members__list"
      >
        <li
          v-for="member in activeMembersFiltered"
          :key="member.id"
          class="members__item"
          @click="clickSelectMember(member)"
        >
          <div class="left-block">
            <status-avatar-widget
              :src="member.avatar"
              :activity="member.last_activity"
            />
            <p class="member-name body-m-medium neutral-900--text mb-0">
              {{ member.name }}
            </p>
          </div>
          <iconify-icon
            v-if="isSelectedMember(member.id)"
            class="icon-check"
            icon="octicon-check-circle-fill-16"
            width="21"
          />
        </li>
      </ul>
      <div
        v-else
        class="member__empty"
      >
        <base-empty
          text="Клиенты не найдены :’("
          sub-text="Скорее всего, все доступные клиенты уже были добавлены! "
        />
      </div>
      <v-btn
        v-if="activeMembersFiltered.length"
        class="members__btn-create"
        color="primary"
        :loading="loadingSave"
        @click="clickSave()"
      >
        <iconify-icon
          class="icon-success"
          icon="ion-checkmark-circle-outline"
          width="21"
        />
        <p class="body-m-semibold mb-0">
          Сохранить
        </p>
      </v-btn>
    </div>
  </div>
</template>

<script>
  // components
  import StatusAvatarWidget from '@/views/communications/widgets/StatusAvatarWidget'
  // other
  import FuzzySearch from 'fuzzy-search'

  export default {
    components: {
      StatusAvatarWidget,
    },
    props: {
      show: Boolean,
      title: {
        type: String,
        default: '',
      },
      editTopicId: {
        type: Number,
        default: NaN,
      },
      selectedMembers: {
        type: Array,
        default () {
          return []
        },
      },
    },
    data () {
      return {
        searchMembers: '',
        loadingSave: false,
        internalSelectedMembers: [],
      }
    },
    computed: {
      conversationId () {
        return this.$store.getters['chat/conversation/currentConversationId']
      },
      topics () {
        return this.$store.getters['chat/topic/topics']
      },
      topic () {
        return this.topics.find(t => t.id === this.editTopicId)
      },
      activeMembersFiltered () {
        let members = this.activeMembers
        const search = String(this.searchMembers).replace(/\s+/g, ' ').replace(/^\s/g, '')

        if (this.searchMembers && search.length) {
          members = this.activeMembersSearcher.search(search.toLowerCase())
        }

        return members
      },
      chatUser () {
        return this.$store.getters['chat/chatUser/chatUser']
      },
      activeMembersSearcher () {
        return new FuzzySearch(
          this.activeMembers,
          ['name'],
          {
            caseSensitive: false,
          },
        )
      },
      activeMembers () {
        let members = this.members.filter(m => m.active)
        if (this.editTopicId && !isNaN(this.editTopicId) && this.topic) {
          members = this.members.filter(m => m.id !== this.topic.owner_id)
        } else {
          members = this.members.filter(m => m.id !== this.chatUser.id)
        }
        return members
      },
      members () {
        let members = this.$store.getters['chat/data/members'](this.conversationId)
        const memberIds = this.selectedMembers.map(m => m.id)
        members = members.filter(m => {
          if (memberIds.includes(m.id)) return false
          return true
        })
        return members
      },
    },
    watch: {
      show () {
        this.internalSelectedMembers = Object.assign([], this.selectedMembers)
      },
    },
    mounted () {
      this.$nextTick(() => {
        window.addEventListener('resize', this.listMaxHeight)
      })
    },
    updated () {
      this.listMaxHeight()
    },
    methods: {
      listMaxHeight () {
        if (this.$refs?.addMemberList) {
          this.$refs.addMemberList.style.maxHeight = document.body.clientHeight - 433 + 'px'
        }
      },
      isSelectedMember (memberId) {
        return this.internalSelectedMembers.map(m => m.id).includes(memberId)
      },
      clickSave () {
        this.$emit('update:selectedMembers', this.internalSelectedMembers)
        this.$emit('back')
      },
      clickSelectMember (member) {
        const selectedMemberIdx = this.internalSelectedMembers.findIndex(m => m.id === member.id)
        if (selectedMemberIdx === -1) {
          this.internalSelectedMembers.push(member)
        } else {
          this.internalSelectedMembers = this.internalSelectedMembers.filter(m => m.id !== member.id)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
