import { mapGetters } from 'vuex'

export default {
  computed: {
    loadingSend () {
      return this.$store.getters['chat/message/loading']
    },
    loadingMessagePage: {
      get () {
        return this.$store.getters['chat/message/loadingMessagePage']
      },
      set (v) {
        this.$store.commit('chat/message/loadingMessagePage', v)
      },
    },
    profile () {
      return this.$store.getters.user
    },
    programId () {
      return this.$store.getters['brand/program/programId']
    },
    chatUser () {
      return this.$store.getters['chat/chatUser/chatUser']
    },
    loadingConversations () {
      return this.$store.getters['chat/conversation/loadingConversations']
    },
    currentConversationId () {
      return this.$store.getters['chat/conversation/currentConversationId']
    },
    members () {
      return this.$store.getters['chat/data/members'](this.currentConversationId)
    },
    isGroup () {
      return !this.conversation.is_private
    },
    currentConversationType () {
      return this.$store.getters['chat/conversation/currentConversationType']
    },
    conversationProgram () {
      return this.$store.getters['chat/data/conversationProgram'](this.currentConversationId)
    },
    realChatName () {
      return this.$store.getters['chat/data/realChatName'](this.currentConversationId)
    },
    conversation () {
      return this.$store.getters['chat/data/conversation'](this.currentConversationId)
    },
    messages () {
      let messages = {}
      if (this.currentConversationId) {
        messages = this.$store.getters['chat/message/messages'][
          this.currentConversationId
        ]
        if (messages && Object.keys(messages).length) {
          const identMessages = { ...messages }
          for (const id in messages) {
            if (!identMessages[id]?.uuid) {
              identMessages[id].uuid = this.$uuid()
            }
          }
          messages = identMessages
          messages = this.connectingMessages(messages)
        }
      }

      return messages || {}
    },
    // sidepanel data
    ...mapGetters({
      clientInfoPanel: 'chat/sidePanel/clientInfoPanel',
    }),
    showChatEditPanel: {
      get () {
        return this.$store.getters['chat/sidePanel/showChatEditPanel']
      },
      set (v) {
        this.$store.commit('chat/sidePanel/showChatEditPanel', v)
      },
    },
  },
}
