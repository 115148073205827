<template>
  <v-dialog
    v-if="internalShow"
    v-model="internalShow"
    max-width="80%"
    content-class="dialog-attachment-image dialog-attachment-preview"
  >
    <div class="preview-img">
      <img
        :src="content.url"
        alt="image"
      >
    </div>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      show: Boolean,
      content: {
        required: true,
        type: Object,
      },
    },
    data () {
      return {
        internalShow: false,
      }
    },
    watch: {
      show (v) {
        this.internalShow = v
      },
      internalShow (v) {
        this.$emit('update:show', v)
      },
    },
  }
</script>
