export default {
  data () {
    const avatar404 = this.$config.defaultPath.avatar404
    return {
      img404: avatar404,
      colors: [
        '#f44336',
        '#673ab7',
        '#1e88e5',
        '#039be5',
        '#009688',
        '#4caf50',
        '#cddc39',
        '#ffb300',
        '#fb8c00',
        '#ff5722',
      ],
      declensionsMember: ['участник', 'участника', 'участников'],
      declensionsMinute: ['минута', 'минуты', 'минут'],
      declensionsHour: ['час', 'часа', 'часов'],
      declensionsDay: ['день', 'дня', 'дней'],
      declensionsTopic: ['тема', 'темы', 'тем'],
      declensionsWriter: ['писатель', 'писателя', 'писателей'],
      declensionsReader: ['читатель', 'читатели', 'читателей'],
    }
  },
  computed: {
  },
  methods: {
    isOnline (date) {
      const time = new Date(date).getTime()
      const currentDate = this.$moment()
      const activityDate = this.$moment(time).local()
      return currentDate.diff(activityDate, 'minutes') < 5
    },
    getOnlineDate (date) {
      let text = 'был(а) '
      const time = new Date(date).getTime()
      const currentDate = this.$moment()
      const activityDate = this.$moment(time).local()

      const diffMinutes = currentDate.diff(activityDate, 'minutes')
      const diffHours = currentDate.diff(activityDate, 'hours')

      if (diffMinutes < 60) {
        if (diffMinutes < 2) text += 'в сети только что'
        else text += 'в сети ' + diffMinutes + ' ' + this.declOfNum(diffMinutes, this.declensionsMinute) + ' назад'
      } else if (diffHours <= 12) {
        text += 'в сети ' + diffHours + ' ' + this.declOfNum(diffHours, this.declensionsHour) + ' назад'
      } else {
        text += activityDate.format('DD.MM.YY')
      }
      return text
    },
    getDate (date) {
      if (!date) return '-'
      const time = new Date(date).getTime()
      const currentDate = this.$moment()
      const relativeDate = this.$moment(time).local()

      let shortMonth = relativeDate.format('MMM')
      if (shortMonth.lastIndexOf('.') > -1) shortMonth = shortMonth.slice(0, -1)
      const year = relativeDate.format('YYYY')
      const day = relativeDate.format('D')
      const DayAndMounth = day + ' ' + shortMonth

      if (currentDate.diff(relativeDate, 'years') === 0) {
        if (currentDate.diff(relativeDate, 'days') === 0) {
          return relativeDate.format('H:mm')
        } else {
          return DayAndMounth
        }
      }

      return DayAndMounth + ', ' + year
    },
    getGroupImgData (msgItem) {
      const imgColor = this.generateColorByNumber(msgItem.id)
      const imgWidth = 48
      const imgHeight = 48

      const
        cvs = document.createElement('canvas')
      const ctx = cvs.getContext('2d')

      cvs.width = imgWidth
      cvs.height = imgHeight
      cvs.style.display = 'block'

      // Fill background
      ctx.moveTo(0, 0)
      ctx.lineTo(imgWidth, 0)
      ctx.lineTo(imgWidth, imgHeight)
      ctx.lineTo(0, imgHeight)
      ctx.fillStyle = imgColor
      ctx.fill()

      // Draw font
      ctx.fillStyle = '#fff'
      ctx.font = '700 28px Gilroy'
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      if (msgItem.name) ctx.fillText(msgItem.name.charAt(0).toUpperCase(), imgWidth / 2, imgHeight / 2 + 1)
      if (msgItem.display_name) ctx.fillText(msgItem.display_name.charAt(0).toUpperCase(), imgWidth / 2, imgHeight / 2 + 1)

      const imgData = cvs.toDataURL()
      cvs.remove()
      return imgData
    },
    getAuthorName (msgItem, payload) {
      if (!payload) return

      const author = this.getAuthor(msgItem, payload)
      let isEmployee = false

      if (msgItem.sender_id === payload.chatUser.id) isEmployee = true

      if (isEmployee) {
        if (author.id) {
          return `${payload.conversationProgram.name} (${author.name})`
        } else if (msgItem.real_sender_id === payload.chatUser.id) { // реальный отправитель чат-бот
          return payload.chatUser.name
        }
      } else {
        if (author.id) return `${author.name}`
      }

      return 'Пользователь'
    },
    getAvatar (payload) {
      let avatar
      const activeMembers = payload.members.filter(m => m.active && m.id !== payload.chatUser.id)

      // есть чат пользователь
      if (payload.chatUser && payload.chatUser.id) {
        if (activeMembers.length === 1) {
          avatar = payload.conversation.members[0].avatar
        }

        if (payload.conversation.is_private) {
          const member = payload.conversation.members.filter(item => item.id !== payload.chatUser.id)
          if (member.length) {
            avatar = member[0].avatar
          }
        } else {
          avatar = this.getGroupImgData(payload.conversation)
        }
      }

      return avatar
    },
    getAuthorAvatar (msgItem, payload) {
      if (!payload) return

      const author = this.getAuthor(msgItem, payload)
      if (author.id) return author.avatar
      // реальный отправитель чат-бот
      else if (msgItem.real_sender_id === this.chatUser.id) {
        return payload.chatUser.avatar
      }
      return this.img404
    },
    getAuthor (msgItem, payload) {
      if (!payload) return

      let authorId = null
      let author = []
      let isEmployee = false

      if (msgItem.sender_id === payload.chatUser.id) isEmployee = true

      if (isEmployee) {
        authorId = msgItem.real_sender_id
        author = payload.employees.filter((item) => item.id === authorId)
        if (author.length) return author[0]
      } else {
        authorId = msgItem.sender_id
        author = payload.members.filter((item) => item.id === authorId)
        if (author.length) return author[0]
      }

      return {}
    },
    getShortMessage (msgItem) {
      const message = msgItem
      if (message) {
        if (message && message.attachments.length) {
          if (message.attachments[0].type === 'message/text') {
            return message.attachments[0].content
          }
          if (message.attachments[0].type === 'plus/account') return 'карта'
          if (message.attachments[0].type === 'media/image') {
            return 'изображение'
          }
          if (message.attachments[0].type === 'media/audio') {
            return 'аудиосообщение'
          }
          if (message.attachments[0].type === 'media/video') return 'видео'
          if (message.attachments[0].type === 'media/file') return 'файл'
        }
        return message.message
      }
      return ''
    },
  },
}
