<template>
  <div class="attachment-file">
    <div class="file">
      <p class="body-m-medium neutral-900--text mb-0">
        Имя файла: {{ content.name }}
      </p>
    </div>
    <p class="body-m-medium neutral-900--text mb-0">
      Размер файла: {{ getFileSize(content.size) }}
    </p>
    <a
      :href="content.url"
      class="url"
      target="_blank"
    >Скачать</a>
  </div>
</template>

<script>
  export default {
    props: {
      content: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {

      }
    },
    methods: {
      getFileSize (bytes) {
        const fSExt = ['Bytes', 'KB', 'MB', 'GB']
        let i = 0
        while (bytes > 900) {
          bytes /= 1024
          i++
        }
        return (Math.round(bytes * 100) / 100) + ' ' + fSExt[i]
      },
    },
  }
</script>
