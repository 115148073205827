export function filterAudioData (audioBuffer, samples) { // audioBuffer = 3920 samples = 52
  const rawData = audioBuffer
  const blockSize = Math.floor(rawData.length / samples) // blockSize = 75
  const filteredData = []
  for (let i = 0; i < samples; i++) { // two iterable
    const blockStart = blockSize * i // 75 * 1 = 75
    let sum = 0
    for (let j = 0; j < blockSize; j++) {
      sum += Math.abs(rawData[blockStart + j])
    }
    filteredData.push(sum / blockSize)
  }
  return filteredData
}

export function normalizeAudioData (filteredData) {
  const multiplier = Math.pow(Math.max(...filteredData), -1)
  return filteredData.map(n => n * multiplier)
}
