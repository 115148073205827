<template>
  <v-dialog
    v-model="internalShow"
    max-width="80%"
    content-class="dialog-attach-files dialog-attachment-preview"
  >
    <div class="attach-files__header">
      <div class="attach-files__title">
        <p class="body-l-semibold">
          Прикрепить файл
        </p>
      </div>
      <div class="attach-files__close">
        <v-btn text>
          <iconify-icon
            class="close neutral-500--text"
            icon="ion-close"
            width="21"
            @click="close"
          />
        </v-btn>
      </div>
    </div>
    <div class="attach-files__content">
      <div class="attached-files__container">
        <template v-if="attachedImgFiles.length">
          <div
            v-for="file in attachedImgFiles"
            :key="file.uuid"
            class="attached-file__box-img"
          >
            <v-img
              class="box-img"
              :src="file.preview"
              alt=""
            />
            <div class="box-control">
              <v-btn
                class="btn-edit"
                text
                :ripple="false"
                @click="clickEditInputFile(file.uuid)"
              >
                <iconify-icon
                  class="icon-edit neutral-500--text"
                  icon="feather-edit"
                  width="16"
                />
              </v-btn>
              <v-btn
                class="btn-close"
                text
                :ripple="false"
                @click="files.length === 1 ? clickEditInputFile(file.uuid) : deleteFile(file.uuid)"
              >
                <iconify-icon
                  class="icon-close neutral-500--text"
                  icon="ion-close"
                  width="21"
                />
              </v-btn>
              <input
                :id="'edit-file-' + file.uuid"
                type="file"
                class="d-none"
                @change="editAttachFile(file.uuid)"
              >
            </div>
          </div>
        </template>
        <template v-if="attachedOtherFiles.length">
          <div
            v-for="file in attachedOtherFiles"
            :key="file.uuid"
            class="attached-file__box-file"
          >
            <div class="left-block">
              <div class="document">
                <v-icon
                  style="margin-left: -2px;margin-top: -1px;"
                  color="primary"
                  size="15"
                >
                  $icons_file
                </v-icon>
              </div>
              <div class="file__info">
                <p class="file-name body-l-semibold neutral-900--text">
                  {{ getShortFileName(file.file.name) }}
                </p>
                <p class="file-size body-s-regular neutral-500--text">
                  {{ getFileSize(file.file.size) }}
                </p>
              </div>
            </div>
            <div class="box-control">
              <v-btn
                class="btn-edit"
                text
                :ripple="false"
                @click="clickEditInputFile(file.uuid)"
              >
                <iconify-icon
                  class="icon-edit neutral-500--text"
                  icon="feather-edit"
                  width="16"
                />
              </v-btn>
              <v-btn
                class="btn-close"
                text
                :ripple="false"
                @click="files.length === 1 ? clickEditInputFile(file.uuid) : deleteFile(file.uuid)"
              >
                <iconify-icon
                  class="icon-close neutral-500--text"
                  icon="ion-close"
                  width="21"
                />
              </v-btn>
              <input
                :id="'edit-file-' + file.uuid"
                type="file"
                class="d-none"
                @change="editAttachFile(file.uuid)"
              >
            </div>
          </div>
        </template>
      </div>
      <div class="attach-files__control">
        <v-btn
          class="btn-add"
          text
          :ripple="false"
          @click="$refs.attachedFiles.click()"
        >
          <iconify-icon
            class="icon-plus-circle primary--text"
            icon="plus-circle"
            width="21"
          />
          <p class="body-s-semibold primary--text">
            Добавить
          </p>
        </v-btn>
        <form ref="formAttachedFile">
          <input
            ref="attachedFiles"
            class="input-file"
            multiple
            type="file"
            @change="handlerAttachFiles()"
          >
        </form>
      </div>
    </div>
    <div class="attach-files__footer">
      <app-send-box
        :is-portable="true"
        portable-class="attach-file--sendbox"
        @message-sent="messageSent"
      />
    </div>
  </v-dialog>
</template>

<script>
  import AppSendBox from '../../ConversationSendBox'

  export default {
    components: {
      AppSendBox,
    },
    props: {
      show: Boolean,
    },
    data () {
      return {
        files: [],
        internalShow: this.show,
      }
    },
    computed: {
      attachedImgFiles () {
        return this.files.filter(f => {
          return this.isTypeImg(f.file.type)
        })
      },
      attachedOtherFiles () {
        return this.files.filter(f => {
          return !this.isTypeImg(f.file.type)
        })
      },
      sending () {
        return this.$store.getters['chat/sendbox/sending']
      },
      fileForwarding () {
        return this.$store.getters['chat/sendbox/fileForwarding']
      },
    },
    watch: {
      show (v) {
        if (v) {
          this.$store.commit('chat/sendbox/isPortableBlock', true)
          this.$store.commit('chat/sendbox/isEdit', false)
        } else {
          this.$store.commit('chat/sendbox/isEmoji', false)
          this.$store.commit('chat/sendbox/isPortableBlock', false)
          this.$store.commit('chat/sendbox/attachFiles', [])
          this.$store.commit('chat/sendbox/fileForwarding', [])
          this.files = []
          if (this.$refs.formAttachedFile) {
            this.$refs.formAttachedFile.reset()
          }
        }
        this.internalShow = v
      },
      internalShow (v) {
        this.$emit('update:show', v)
      },
      fileForwarding (v) {
        if (v && v.length) {
          this.handlerAttachFiles(v)
        }
      },
      sending (v) {
        if (!v) {
          this.files = []
          this.$emit('message-sent')
        }
      },
    },
    methods: {
      close () {
        this.internalShow = false
      },
      messageSent () {
        this.$refs.formAttachedFile.reset()
        this.$emit('update:show', false)
      },
      async handlerAttachFiles (files, uuid) {
        let inputFiles
        if (files && files.length) inputFiles = files
        else if (this.$refs.attachedFiles?.files?.length) inputFiles = this.$refs.attachedFiles.files
        const result = []

        if (inputFiles && inputFiles.length) {
          for (let i = 0; i < inputFiles.length; i++) {
            if (this.isValidType(inputFiles[i].type)) {
              const filesEqual = this.files.filter(f => !this.isEqualFile(f.file, inputFiles[i]))
              if (this.files.length === filesEqual.length) {
                if (uuid && uuid.length) {
                  this.files = this.files.filter(f => !uuid.includes(f.uuid))
                }
                const pushIdx = result.push({ uuid: this.$uuid(), file: inputFiles[i] })
                if (this.isTypeImg(inputFiles[i].type)) {
                  result[pushIdx - 1].preview = await this.getBase64(inputFiles[i])
                }
                this.files = Array.from(new Set(this.files.concat(result)))
              } else {
                continue
              }
            } else {
              this.notifyNotValidType(inputFiles[i].type)
            }
          }
        }

        if (!this.files.length) this.internalShow = false

        this.$store.commit('chat/sendbox/attachFiles', this.files.map(f => f.file))

        if (
          (!files || !files.length) &&
          this.$refs.attachedFiles.files
        ) {
          this.$refs.formAttachedFile.reset()
        }
      },
      isEqualFile (file1, file2) {
        return (
          file1.name === file2.name &&
          file1.size === file2.size &&
          file1.type === file2.type &&
          file1.lastModified === file2.lastModified
        )
      },
      getShortFileName (fileName) {
        if (fileName.length > 25) {
          return fileName.substr(0, 5) + '...' + fileName.substr(fileName.length - 6)
        }
        return fileName
      },
      getFileSize (bytes) {
        const fSExt = ['Bytes', 'KB', 'MB', 'GB']
        let i = 0
        while (bytes > 900) {
          bytes /= 1024
          i++
        }
        return (Math.round(bytes * 100) / 100) + ' ' + fSExt[i]
      },
      getBase64 (file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => resolve(reader.result)
          reader.onerror = error => reject(error)
        })
      },
      async editAttachFile (uuid) {
        const inputFile = document.getElementById('edit-file-' + uuid).files[0]
        await this.handlerAttachFiles([inputFile], uuid)
      },
      clickEditInputFile (uuid) {
        document.getElementById('edit-file-' + uuid).click()
      },
      isValidType (type) {
        if (this.$config.chat.files.typesAttached.length) {
          return this.$config.chat.files.typesAttached.includes(type)
        }
        return true
      },
      notifyNotValidType (type) {
        if (!type.length) type = '`неизвестно`'
        this.$notify({
          title: 'Валидация:',
          text: 'Нельзя прикрепить файл типа ' + type,
          type: 'error',
        })
      },
      isTypeImg (type) {
        return (type === 'image/png') ||
          (type === 'image/jpg') ||
          (type === 'image/jpeg')
      },
      deleteFile (uuid) {
        this.files = this.files.filter(f => {
          return (f.uuid !== uuid)
        })
      },
    },
  }
</script>
