<template>
  <div
    class="attachment-audio"
    :style="msgItem.parent_id ? 'margin-top: 12px; margin-left: -7px;' : ''"
  >
    <preview-audio
      :content="content"
      :strip-count="52"
      :source="audioSource"
      @audioSource="saveInMsgItemAudioSource"
    />
  </div>
</template>

<script>
  import PreviewAudio from './preview/PreviewAudio'

  export default {
    components: {
      PreviewAudio,
    },
    props: {
      index: {
        type: Number,
        default: 0,
      },
      msgItem: {
        type: Object,
        default: () => {
          return {}
        },
      },
      content: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
      }
    },
    computed: {
      audioSource () {
        if (this.msgItem?.parent_message?.attachments?.length) {
          return this.msgItem.parent_message.attachments[this.index].audioSource
        } else {
          return this.msgItem.attachments[this.index].audioSource
        }
      },
    },
    watch: {
    },
    mounted () {
    },
    methods: {
      saveInMsgItemAudioSource (audioSource) {
        if (this.msgItem?.parent_message?.attachments?.length) {
          this.msgItem.parent_message.attachments[this.index].audioSource = audioSource
        } else {
          this.msgItem.attachments[this.index].audioSource = audioSource
        }
      },
    },
  }
</script>
