<template>
  <div
    class="group-topic"
  >
    <div class="group-topic__header">
      <p class="title-m-bold neutral-900--text">
        Новая тема
      </p>
    </div>
    <div
      class="group-topic__content"
    >
      <div class="topic__fields">
        <div class="topic-field">
          <p class="topic-name body-l-semibold neutral-900--text">
            Название темы
          </p>
          <p class="topic-description body-m-regular-neutral-700--text">
            Придумывайте осмысленные навазния для тем, чтобы участники чата понимали о чем идет речь.
          </p>
          <base-text-field
            v-model="topicName"
            class="topic-field"
            placeholder="Введите название темы"
            hide-details
          />
        </div>
        <div class="topic-field">
          <p class="body-l-semibold neutral-900--text">
            Участники
          </p>
          <p class="body-m-regular-neutral-700--text">
            Все участники чата видят сообщения с этой темой. Вы можете включать и выключать возможность писать сообщения с этой темой отдельным участникам.
          </p>
          <ul
            ref="memberListNew"
            class="members__list"
          >
            <li
              v-for="member in assortedSelectedMembers"
              :key="member.id"
              class="member__item"
            >
              <div class="left-block">
                <status-avatar-widget
                  :src="member.avatar"
                  :activity="member.last_activity"
                />
                <p class="name body-m-medium neutral-900--text">
                  {{ member.name }}
                </p>
              </div>
              <div
                v-if="member.id !== chatUser.id"
                class="right-block"
              >
                <base-ext-switch
                  v-model="membersCanWrite[member.id]"
                  class="status"
                  :label="membersCanWrite[member.id] ? 'Пишит' : 'Читает'"
                />
                <v-btn
                  class="btn__delete-member"
                  text
                  @click="deleteMember(member.id)"
                >
                  <iconify-icon
                    class="icon-close neutral-500--text"
                    icon="ion-close"
                    width="21"
                  />
                </v-btn>
              </div>
            </li>
          </ul>
          <v-btn
            class="members__btn-create"
            text
            @click="$emit('add-member')"
          >
            <iconify-icon
              class="icon-plus primary-base--text"
              icon="feather-plus-circle"
              width="21"
            />
            <p class="body-s-semibold primary-base--text mb-0">
              Добавить участника
            </p>
          </v-btn>
        </div>
      </div>
    </div>
    <div
      class="group-topic__footer"
    >
      <div class="topic__controls">
        <v-btn
          class="create__btn"
          color="primary"
          :loading="isRequestCreate"
          @click="createTopic()"
        >
          <iconify-icon
            icon="ion-checkmark-circle-outline"
            class="icon icon-check"
            width="21"
          />
          <p class="body-m-semibold neutral-100--text">
            Создать тему
          </p>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
  // mixins
  import MixinIndex from '@/views/communications/mixins/index.js'
  // components
  import StatusAvatarWidget from '@/views/communications/widgets/StatusAvatarWidget'

  export default {
    components: {
      StatusAvatarWidget,
    },
    mixins: [
      MixinIndex,
    ],
    props: {
      show: Boolean,
      initComponent: Boolean,
      selectedMembers: {
        type: Array,
        default () {
          return []
        },
      },
    },
    data () {
      return {
        topicName: '',
        membersCanWrite: {},
        isRequestCreate: false,
      }
    },
    computed: {
      conversationId () {
        return this.$store.getters['chat/conversation/currentConversationId']
      },
      chatUser () {
        return this.$store.getters['chat/chatUser/chatUser']
      },
      topicPanelModes () {
        return this.$store.getters['chat/sendbox/topicPanel']
      },
      members () {
        return this.$store.getters['chat/data/members'](this.conversationId)
      },
      activeMembers () {
        return this.members.filter(m => m.active && m.id !== this.chatUser.id)
      },
      myMember () {
        return this.members.find(m => m.id === this.chatUser.id) || this.chatUser
      },
      assortedSelectedMembers () {
        let assorted = [...this.selectedMembers]
        if (this.myMember) {
          assorted = assorted.filter(m => m.id !== this.chatUser.id)
          assorted.unshift(this.myMember)
        }
        return assorted
      },
    },
    watch: {
      show (v) {
        if (v) {
          this.init()
        }
      },
    },
    mounted () {
      this.$nextTick(() => {
        window.addEventListener('resize', this.listMaxHeight)
      })
    },
    updated () {
      this.listMaxHeight()
    },
    methods: {
      listMaxHeight () {
        if (this.$refs?.memberListNew) {
          this.$refs.memberListNew.style.maxHeight = document.body.clientHeight - 584 + 'px'
        }
      },
      setMembersCanWrite (payload) {
        this.membersCanWrite = payload
      },
      createTopic () {
        const members = this._prepareRequestMembers()

        const myMember = Object.assign({}, this.myMember)
        myMember.can_write = true
        members.push(myMember)

        const topic = {
          conversation_id: this.conversationId,
          name: this.topicName,
          is_private: false,
          members,
        }

        this.isRequestCreate = true
        this.$store.dispatch('chat/topic/create', topic).then(() => {
          this.$emit('close')
          this.membersCanWrite = {}
          this.topicName = ''
        }).finally(() => {
          this.isRequestCreate = false
        })
      },
      init () {
        if (this.initComponent) {
          this.topicName = ''
          this.$emit('update:initComponent', false)
        }
      },
      deleteMember (memberId) {
        this.$emit('update:selectedMembers', this.selectedMembers.filter(m => m.id !== memberId))
      },
      _prepareRequestMembers () {
        let members = []
        for (let i = 0; i < this.selectedMembers.length; i++) {
          members.push(Object.assign({}, this.selectedMembers[i]))
        }

        members = members.map(m => {
          m.can_write = false
          return m
        })

        if (Object.keys(this.membersCanWrite).length) {
          for (const id in this.membersCanWrite) {
            const currMemberIdx = members.findIndex(m => Number(m.id) === Number(id))
            if (currMemberIdx !== -1) {
              members[currMemberIdx].can_write = this.membersCanWrite[id]
            }
          }
        }

        return members
      },
    },
  }
</script>
