<template>
  <div
    class="app--chat--empty"
    style="margin: auto"
  >
    <base-empty
      v-if="!loading"
      text="Чат компании не настроен"
      sub-text="Настройте чат компании чтобы клиенты могли
      обращаться с вопросами напрямую"
    />
    <v-progress-circular
      v-else
      :size="60"
      :width="5"
      color="neutral-400"
      indeterminate
    />
    <div class="controls">
      <v-btn
        v-if="!loading"
        class="btn-setting"
        color="primary-100"
        @click="openSettingsChat()"
      >
        <iconify-icon
          class="icon-setting primary-base--text"
          icon="feather-settings"
          width="21"
        />
        <p class="body-m-semibold primary--text mb-0">
          Настроить чаты
        </p>
      </v-btn>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      loading: Boolean,
    },
    methods: {
      openSettingsChat () {
        this.toRoute('/settings/chat')
      },
      toRoute (path) {
        if (
          this.$route.path !== path &&
          ['ChatConversation', 'ChatType', 'Chat'].includes(this.$route.name)
        ) { this.$router.push(path) }
      },
    },
  }
</script>
