<template>
  <base-side-panel
    v-model="innerActiveSidePanel"
    class="side-panel__chat-edit-user"
    :width="483"
  >
    <div
      class="b-mute"
      @click="setMuted(conversation)"
    >
      <v-btn
        class="btn-volume"
        color="neutral-500"
        :loading="loadingMuted"
        :ripple="false"
        text
      >
        <v-icon
          v-if="conversation.muted"
          class="icon-volume"
          color="neutral-500"
          size="21"
        >
          $icons_speaker-on
        </v-icon>
        <v-icon
          v-else
          class="icon-volume"
          color="neutral-500"
          size="21"
        >
          $icons_speaker-off
        </v-icon>
      </v-btn>
    </div>
    <div class="chat-edit-user__header">
      <div class="left-block">
        <p class="header-name title-m-bold neutral-900--text">
          {{ authorName }}
        </p>
        <p class="header-online-date body-s-semibold neutral-600--text">
          {{ getOnlineDate(conversation.creator_last_activity) }}
        </p>
      </div>
      <v-img
        class="header-avatar"
        max-width="67"
        max-height="67"
        :src="headerAvatar"
        @error="errorHeaderAvatar = true"
      />
    </div>
    <div class="chat-edit-user__content">
      <ul class="contacts-info__list">
        <li class="contacts-info__item">
          <iconify-icon
            class="info__item-icon"
            icon="ion-phone-portrait-outline"
            width="21"
          />
          <p class="body-m-medium neutral-700--text">
            {{ (accountClient.user && accountClient.user.phone) ? accountClient.user.phone : '-' }}
          </p>
        </li>
        <li class="contacts-info__item">
          <iconify-icon
            class="info__item-icon"
            icon="ion-mail-outline"
            width="21"
          />
          <p class="body-m-medium neutral-700--text">
            {{ (accountClient.user && accountClient.user.email) ? accountClient.user.email : '-' }}
          </p>
        </li>
        <li class="contacts-info__item">
          <iconify-icon
            class="info__item-icon"
            icon="ion-barcode-outline"
            width="21"
          />
          <p class="body-m-medium neutral-700--text">
            {{ accountClient.barcode || '-' }}
          </p>
        </li>
        <li class="contacts-info__item">
          <iconify-icon
            class="info__item-icon"
            icon="feather-credit-card"
            width="21"
          />
          <p class="body-m-medium neutral-700--text">
            {{ accountClient.number || '-' }}
          </p>
        </li>
      </ul>
      <div class="content__topics">
        <p class="topics__title body-l-semibold neutral-900--text">
          {{ topics.length + ' ' + declOfNum(topics.length, declensionsTopic) }}
        </p>
        <ul class="topics__list">
          <li
            v-for="topic in topics"
            :key="topic.id"
            class="topics__item"
          >
            <div class="left-block">
              <p class="item-name body-m-semibold neutral-900--text mb-0">
                {{ topic.name }}
              </p>
            </div>
            <v-btn
              class="topic__btn-edit"
              color="primary-100"
              @click="openTopicPanel()"
            >
              <iconify-icon
                class="icon-edit primary-base--text"
                icon="feather-edit-2"
                width="15"
              />
            </v-btn>
          </li>
        </ul>
        <v-btn
          class="topics__btn-create"
          color="primary"
          @click="openTopicPanel()"
        >
          <iconify-icon
            class="icon-create neutral-100--text"
            icon="feather-plus-circle"
            width="21"
          />
          <p class="body-m-semibold neutral-100--text mb-0">
            Новая тема
          </p>
        </v-btn>
      </div>
      <media-widget :active-tabs="[1, 2, 3, 4, 5]" />
    </div>
  </base-side-panel>
</template>

<script>
  // mixins
  import MixinIndex from '@/views/communications/mixins/index'
  // components
  import MediaWidget from '@/views/communications/widgets/MediaWidget'

  export default {
    components: {
      MediaWidget,
    },
    mixins: [MixinIndex],
    model: {
      prop: 'activeSidePanel',
      event: 'changeSidePanel',
    },
    props: {
      activeSidePanel: Boolean,
    },
    data () {
      return {
        loadingMuted: false,
        errorHeaderAvatar: false,
        innerActiveSidePanel: this.activeSidePanel,
      }
    },
    computed: {
      conversationId () {
        return this.$store.getters['chat/conversation/currentConversationId']
      },
      conversation () {
        return this.$store.getters['chat/data/conversation'](this.conversationId)
      },
      accountClient () {
        return this.$store.getters['account/account/userAccountById']
      },
      topics () {
        return this.$store.getters['chat/topic/topics']
      },
      authorName () {
        let name
        this.payload.members.forEach(item => {
          if (item.id !== this.payload.chatUser.id) name = item.display_name
        })
        if (!name) name = this.conversation.display_name
        return name
      },
      member () {
        return this.payload.members.filter(m => {
          if (m.id !== this.payload.chatUser.id) return true
          return false
        })[0]
      },
      payload () {
        return this.$store.getters['chat/data/payload'](this.conversationId)
      },
      headerAvatar () {
        if (this.errorHeaderAvatar) return this.img404
        return this.getAvatar(this.payload)
      },
      program () {
        return this.$store.getters['company/program/program']
      },
      topicPanelModes () {
        return this.$store.getters['chat/sendbox/topicPanel']
      },
    },
    watch: {
      innerActiveSidePanel (v) {
        this.$emit('changeSidePanel', v)
      },
      activeSidePanel (v) {
        this.innerActiveSidePanel = v
      },
      topicPanelModes: {
        deep: true,
        handler (v) {
          if (v && Object.keys(v).includes('call')) {
            if (v.call.of === 'ChatEditUser' && v.call.direction === 'reception') {
              this.innerActiveSidePanel = true
            }
          }
        },
      },
    },
    mounted () {
      this.$store.dispatch('account/account/getUserAccountById', { userId: this.member.id, programId: this.program.id })
    },
    methods: {
      setMuted (item) {
        const conversation = {
          conversation_id: item.id,
        }
        this.loadingMuted = true
        if (item.muted) {
          this.$store.dispatch('chat/conversation/mutedRemove', conversation)
            .finally(() => {
              this.loadingMuted = false
            })
        } else {
          this.$store.dispatch('chat/conversation/mutedSet', conversation)
            .finally(() => {
              this.loadingMuted = false
            })
        }
      },
      getOnlineDate (date) {
        const time = new Date(date).getTime()
        const activityDate = this.$moment(time).local()
        return 'был(а) в сети ' + activityDate.format(this.$config.date.DATETIME_FORMAT_MIN2)
      },
      openTopicPanel () {
        this.innerActiveSidePanel = false
        this.$store.commit('chat/sendbox/topicPanel', {
          mode: 'create',
          call: {
            of: 'ChatEditUser',
            direction: 'recoil',
          },
        })
        this.$store.commit('chat/sendbox/isTopicPanel', true)
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
