<template>
  <div class="chat__media-widget">
    <p class="media__title body-l-semibold neutral-900--text">
      Медиа
    </p>
    <div class="media__tabs">
      <ul class="tabs__list">
        <li
          v-for="tab in mediaTabs"
          :key="tab.id"
          class="tabs__item"
          :class="{active: tab.active}"
          @click="clickMediaTab(tab.id)"
        >
          <p
            class="body-m-medium mb-0"
            :class="tab.active ? 'primary-base--text' : 'neutral-500--text'"
          >
            {{ tab.name }}
          </p>
        </li>
      </ul>
    </div>
    <div class="media__tabs-content">
      <v-slide-y-transition hide-on-leave>
        <div
          v-show="mediaActiveTab.id === 1"
          class="tab-images"
        >
          <ul class="images__list">
            <li
              v-for="(item, idx) in mediaImages"
              :key="idx"
              class="images__item"
            >
              <img
                class="item-img"
                :src="item.content.thumb_small"
                @click="clickPreviewImage(item.content)"
              >
            </li>
          </ul>
        </div>
      </v-slide-y-transition>
      <v-slide-y-reverse-transition hide-on-leave>
        <div
          v-show="mediaActiveTab.id === 2"
          class="tab-videos"
        >
          <ul class="videos__list">
            <li
              v-for="(item, idx) in mediaVideos"
              :key="idx"
              class="videos__item"
            >
              <div class="item-thumb">
                <img
                  :src="item.content.thumb"
                  alt="img"
                >
              </div>
              <iconify-icon
                class="icon-play"
                icon="feather-play"
                width="21"
                @click="clickPreviewVideo(item.content)"
              />
            </li>
          </ul>
        </div>
      </v-slide-y-reverse-transition>
      <v-slide-y-transition hide-on-leave>
        <div
          v-show="mediaActiveTab.id === 3"
          class="tab-files"
        >
          <ul class="files__list">
            <li
              v-for="(item, idx) in mediaFiles"
              :key="idx"
              class="files__item"
            >
              <a
                :href="item.content.url"
                target="_blank"
                class="files__href"
              >
                <v-img
                  v-if="item.type === 'media/image'"
                  class="item-image"
                  :src="item.content.thumb_small"
                  width="60"
                  height="60"
                />
                <div
                  v-else
                  class="b__icon-file"
                >
                  <iconify-icon
                    class="icon-document"
                    icon="ion-document-outline"
                    width="20"
                  />
                </div>
                <div class="right-block">
                  <p class="item-name body-m-medium neutral-900--text">
                    {{ getShortFileName(item.content.name) }}
                  </p>
                  <p class="item-meta body-s-regular neutral-600--text mb-0">
                    {{ getFileSize(item.content.size) }} {{ getFileDate(item.created_at, ',') }}
                  </p>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </v-slide-y-transition>
      <v-slide-y-reverse-transition hide-on-leave>
        <div
          v-show="mediaActiveTab.id === 4"
          class="tab-links"
        >
          <ul class="links__list">
            <li
              v-for="(item, idx) in mediaLinks"
              :key="idx"
              class="links__item"
            >
              <div
                v-if="item && Object.keys(item.preview).length && !Object.keys(linkImgErrors).includes(item.link)"
                class="item-preview-img"
              >
                <img
                  :src="item.preview.image"
                  alt=""
                  @error="errorLinkImg(item.link)"
                >
              </div>
              <div
                v-else
                class="item-not-img"
              />
              <div class="right-block">
                <p class="item-preview-name body-m-semibold neutral-900--text">
                  {{ (item && Object.keys(item.preview).length) ? item.preview.title : '-' }}
                </p>
                <p class="item-preview-title body-s-medium neutral-900--text">
                  {{ (item && Object.keys(item.preview).length) ? item.preview.description : '-' }}
                </p>
                <a
                  :href="item.link"
                  target="_blank"
                  class="item-preview-src body-s-medium primary-base--text"
                >
                  {{ item.link }}
                </a>
              </div>
            </li>
          </ul>
        </div>
      </v-slide-y-reverse-transition>
      <v-slide-y-transition hide-on-leave>
        <div
          v-show="mediaActiveTab.id === 5"
          class="tab-voices"
        >
          <ul class="voices__list">
            <li
              v-for="(item, idx) in mediaAudios"
              :key="idx"
              class="voices__item"
            >
              <div class="item-text d-flex">
                <p class="text-decription body-m-medium neutral-900--text mb-0">
                  {{ getFileDate(item.attachment.created_at) }},
                </p>
                <p class="text-name body-s-regular neutral-400--text mb-0">
                  {{ getAuthor(item.msgItem, payload).name }}
                </p>
              </div>
              <preview-audio
                :content="item.attachment.content"
                :strip-count="80"
                :strip-min-height="4"
                :strip-max-height="24"
                :duration="false"
              >
                <template v-slot:play="{ isPlay, switchPlayBack }">
                  <v-btn
                    class="btn-play"
                    color="primary"
                    rounded
                    @click="switchPlayBack"
                  >
                    <iconify-icon
                      v-if="!isPlay"
                      class="icon icon-play neutral-100--text"
                      icon="feather-play"
                      width="16"
                    />
                    <iconify-icon
                      v-else
                      class="icon icon-play neutral-100--text"
                      icon="ion-pause-outline"
                      width="16"
                    />
                  </v-btn>
                </template>
              </preview-audio>
            </li>
          </ul>
        </div>
      </v-slide-y-transition>
      <v-slide-y-reverse-transition hide-on-leave>
        <div
          v-show="mediaActiveTab.id === 6"
          class="tab-cards"
        >
          <ul class="voices__list">
            <li
              v-for="(item, idx) in mediaAudios"
              :key="idx"
              class="voices__item"
            >
              <p class="item-decription body-m-medium neutral-900--text">
                {{ getFileDate(item.attachment.created_at) }}, {{ getAuthor(item.msgItem, payload).name }}
              </p>
              <preview-audio
                :content="item.attachment.content"
                :source="getItemAttachmentAudioSource(item.attachment)"
                :strip-count="80"
                :strip-min-height="4"
                :strip-max-height="24"
                :duration="false"
                @audioSource="setItemAudioSource($event, idx)"
              >
                <template v-slot:play="{ isPlay, switchPlayBack }">
                  <v-btn
                    class="btn-play"
                    color="primary"
                    rounded
                    @click="switchPlayBack"
                  >
                    <iconify-icon
                      v-if="!isPlay"
                      class="icon icon-play neutral-100--text"
                      icon="feather-play"
                      width="16"
                    />
                    <iconify-icon
                      v-else
                      class="icon icon-play neutral-100--text"
                      icon="ion-pause-outline"
                      width="16"
                    />
                  </v-btn>
                </template>
              </preview-audio>
            </li>
          </ul>
        </div>
      </v-slide-y-reverse-transition>
    </div>
    <div class="media__previews">
      <preview-image
        :show.sync="isPreviewImage"
        :content="previewImageContent"
      />
      <preview-video
        :show.sync="isPreviewVideo"
        :content="previewVideoContent"
      />
    </div>
  </div>
</template>

<script>
  // mixins
  import MixinIndex from '@/views/communications/mixins/index'
  // components
  import PreviewImage from '@/views/communications/content/components/attachment/preview/PreviewImage'
  import PreviewVideo from '@/views/communications/content/components/attachment/preview/PreviewVideo'
  import PreviewAudio from '@/views/communications/content/components/attachment/preview/PreviewAudio'

  export default {
    components: {
      PreviewImage,
      PreviewVideo,
      PreviewAudio,
    },
    mixins: [
      MixinIndex,
    ],
    props: {
      activeTabs: {
        type: Array,
        required: false,
        default: function () {
          return [1, 2, 3, 4, 5]
        },
      },
    },
    data () {
      return {
        linkImgErrors: [],
        isPreviewImage: false,
        isPreviewVideo: false,
        previewImageContent: {},
        previewVideoContent: {},
        mediaImages: [],
        mediaVideos: [],
        mediaFiles: [],
        mediaLinks: [],
        mediaAudios: [],
        mediaCards: [],
        mediaImagesAndFiles: [],
        mediaTabs: [
          { id: 1, name: 'Фото', active: true },
          { id: 2, name: 'Видео', active: false },
          { id: 3, name: 'Файлы', active: false },
          { id: 4, name: 'Ссылки', active: false },
          { id: 5, name: 'Голосовые', active: false },
          { id: 6, name: 'Карты', active: false },
        ],
      }
    },
    computed: {
      conversationId () {
        return this.$store.getters['chat/conversation/currentConversationId']
      },
      payload () {
        return this.$store.getters['chat/data/payload'](this.conversationId)
      },
      messages () {
        return this.$store.getters['chat/message/messages'][
          this.conversationId
        ]
      },
      mediaActiveTab () {
        return this.mediaTabs.filter(t => t.active)[0]
      },
    },
    watch: {
      messages: {
        deep: true,
        handler (v) {
          this._initMedia()
        },
      },
    },
    mounted () {
      this.filterNonActiveTabs()
      this._initMedia()
    },
    methods: {
      clickPreviewImage (content) {
        this.isPreviewImage = true
        this.previewImageContent = content
      },
      clickPreviewVideo (content) {
        this.isPreviewVideo = true
        this.previewVideoContent = content
      },
      clickMediaTab (tabId) {
        this.mediaTabs = this.mediaTabs.map(t => {
          if (t.id === tabId) t.active = true
          else t.active = false
          return t
        })
      },
      getShortFileName (fileName) {
        if (fileName.length > 25) {
          return fileName.substr(0, 5) + '...' + fileName.substr(fileName.length - 6)
        }
        return fileName
      },
      getFileDate (date, separator = '') {
        if (!date) return '-'
        let text = ''
        const time = new Date(date).getTime()
        const currentDate = this.$moment()
        const relativeDate = this.$moment(time).local()

        let shortMonth = relativeDate.format('MMM')
        if (shortMonth.lastIndexOf('.') > -1) shortMonth = shortMonth.slice(0, -1)
        const year = relativeDate.format('YYYY')
        const day = relativeDate.format('D')
        text += day + ' ' + shortMonth
        if (currentDate.diff(relativeDate, 'years') > 0) text += ' ' + year
        text += separator + ' в ' + relativeDate.format('HH:mm')
        return text
      },
      getFileSize (bytes) {
        const fSExt = ['Bytes', 'Kb', 'Mb', 'Gb']
        let i = 0
        while (bytes > 900) {
          bytes /= 1024
          i++
        }
        return (Math.round(bytes * 100) / 100) + ' ' + fSExt[i]
      },
      filterNonActiveTabs () {
        this.mediaTabs = this.mediaTabs.filter(t => {
          if (this.activeTabs.includes(t.id)) return true
          return false
        })
      },
      _initMedia () {
        this.mediaImages = []
        this.mediaFiles = []
        this.mediaImagesAndFiles = []
        this.mediaLinks = []
        this.mediaAudios = []
        this.mediaVideos = []
        for (const id in this.messages) {
          const m = this.messages[id]
          if (m.attachments.length) {
            m.attachments.forEach(at => {
              if (at.type === 'media/image') {
                this.mediaImages.push(at)
                this.mediaImagesAndFiles.push(at)
              }
              if (at.type === 'media/video') {
                this.mediaVideos.push(at)
              }
              if (at.type === 'media/file') {
                this.mediaFiles.push(at)
                this.mediaImagesAndFiles.push(at)
              }
              if (at.type === 'media/audio') {
                this.mediaAudios.push({ msgItem: m, attachment: at })
              }
            })
          }
          if (m.message && m.message.length) {
            const regex = /(http|https):\/\/([\S]+)/gm
            const matchString = m.message.match(regex)
            if (matchString && matchString[0]) {
              const linkPreviewUrl = 'https://api.linkpreview.net/?key=' + this.$config.apiKeys.linkPreview + '&q='
              fetch(linkPreviewUrl + matchString[0])
                .then((data) => {
                  return data.json()
                })
                .then(data => {
                  const duplicateIdx = this.mediaLinks.findIndex(ln => ln.link === matchString[0])

                  if (duplicateIdx === -1) {
                    this.mediaLinks.push({ link: matchString[0], preview: data })
                  }
                })
            }
          }
        }
      },
      errorLinkImg (link) {
        this.linkImgErrors[link] = true
      },
      getItemAttachmentAudioSource (attahment) {
        return attahment?.audioSource
      },
      setItemAudioSource (audioSource, idx) {
        this.mediaAudios[idx].attachment.audioSource = audioSource
      },
    },
  }
</script>
