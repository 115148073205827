<template>
  <div
    class="app-conversation--sendForm__message__record"
  >
    <div class="record-time">
      <p class="body-m-regular neutral-600--text">
        {{ recordedTime }}
      </p>
    </div>
    <div class="left-block">
      <v-btn
        class="record-cancel"
        color="primary-100"
        @click="cancelRecorder()"
      >
        <iconify-icon
          class="icon-close"
          icon="jam-close"
          width="21"
        />
      </v-btn>
      <v-btn
        class="record-stop"
        color="error"
        @click="stopRecord()"
      >
        <div
          class="stop-overlay"
          :style="`width: calc(130px + ${Math.round(volume * 60)}px);
                   height: calc(130px + ${Math.round(volume * 60)}px);`"
        />
        <iconify-icon
          class="icon-stop"
          icon="carbon-stop"
          width="21"
        />
      </v-btn>
    </div>
  </div>
</template>

<script>
  import { convertTimeMMSS } from '@/utils'

  export default {
    props: {
      isRecording: Boolean,
      isStop: Boolean,
      recordList: {
        type: Array,
        default () {
          return []
        },
      },
      playCurrentTime: {
        type: [Number, String],
        default: '',
      },
      recordPlay: {
        type: [Object, HTMLAudioElement],
        default: new window.Audio(),
      },
      recorder: {
        type: Object,
        default: function () {
          return {}
        },
      },
    },
    data () {
      return {}
    },
    computed: {
      conversationId () {
        return this.$store.getters['chat/conversation/currentConversationId']
      },
      recordedTime () {
        if (this.recorder.duration >= 1800) {
          this.stopRecorder()
        }
        return convertTimeMMSS(this.recorder.duration)
      },
      volume () {
        return parseFloat(this.recorder.volume)
      },
    },
    watch: {
      conversationId () {
        this.cancelRecorder()
      },
    },
    mounted () {},
    methods: {
      cancelRecorder () {
        this.stopRecorder()
        this.deleteAllRecords()
        this.$emit('update:recordList', [])
        this.$emit('update:isStop', false)
        this.$emit('update:recordPlay', {})
        this.$emit('update:playCurrentTime', 0)
      },
      stopRecord () {
        this.stopRecorder()
      },
      stopRecorder () {
        if (!this.isRecording) {
          return
        }
        this.$emit('update:isStop', true)
        this.recorder.stop()
        this.deleteOldRecord()
        const recordList = this.recorder.recordList()
        this.$emit('update:recordList', recordList)
        this.$emit('update:playCurrentTime', 0)
        const recordPlay = new window.Audio()
        recordPlay.src = recordList[0].url
        this.$emit('update:recordPlay', recordPlay)
      },
      deleteOldRecord () {
        if (this.recorder.records.length > 1) {
          this.recorder.records.shift()
        }
      },
      deleteAllRecords () {
        this.recorder.records = []
      },
    },
  }
</script>
