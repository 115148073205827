export default {
  methods: {
    clearForm () {
      this.message = ''
      this.$store.dispatch('chat/sendbox/clearForm')
      this.$store.commit('chat/message/recipients', [])
      this.$store.commit('chat/conversation/setCurrentConversationMessage', '')
      this.$emit('message-sent')
    },
    // отправляем сообщение
    async send () {
      if (this.isSend) return

      if (this.validateSendMessage()) {
        try {
          this.$store.commit('chat/sendbox/isSend', true)
          if (this.isEdit) {
            await this.$store.dispatch('chat/sendbox/sendUpdateMessage')
          } else {
            const textChunks = this.getTextChunks(this.messageText, 1024)
            console.log('textChunks', textChunks)
            for (let i = 0; i < textChunks.length; i++) {
              await this.$store.dispatch('chat/sendbox/sendForm', textChunks[i])
            }
          }
        } catch (e) {
          console.error('send', e)
        } finally {
          this.$store.commit('chat/sendbox/isSend', false)
          this.clearForm()
        }
      }

      this.textAreaFocus()
    },
    getTextChunks (text, chunkSize) {
      if (text.length >= chunkSize) {
        const chunks = []
        let iterableText = text
        while (iterableText.length >= chunkSize) {
          iterableText = text.substr((chunkSize * chunks.length), chunkSize)
          if (iterableText.length) {
            chunks.push(iterableText)
          }
        }
        return chunks
      }

      return [text]
    },
    textAreaFocus () {
      setTimeout(() => {
        this.$refs.messageTextArea.$el.querySelector('textarea').focus()
      }, 0)
    },
    validateSendMessage () {
      if (
        (
          this.messageText &&
          this.formatMessage(this.messageText).replace(/\s+/, ' ').replace(/\s/, '').length
        ) ||
        this.fileForwarding.length
      ) {
        return true
      }
      return false
    },
    clearMessage () {
      this.message = ''
    },
  },
}
