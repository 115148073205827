<template>
  <base-side-panel
    :active="innerActiveSidePanel"
    :static-back-button="true"
    class="side-panel__topics"
    :width="483"
    @back="clickBackButton()"
  >
    <group-topic
      v-if="isGroup"
      :active-side-panel="innerActiveSidePanel"
      :click-back-btn="clickBackBtn"
      @closePanel="closePanel"
    />
    <user-topic
      v-else
      :active-side-panel="innerActiveSidePanel"
      :click-back-btn="clickBackBtn"
      @closePanel="closePanel"
    />
  </base-side-panel>
</template>

<script>
  // components
  import UserTopic from './topic-panel/UserTopic'
  import GroupTopic from './topic-panel/GroupTopic'

  export default {
    components: {
      UserTopic,
      GroupTopic,
    },
    props: {
      activeSidePanel: Boolean,
    },
    data () {
      return {
        innerActiveSidePanel: this.activeSidePanel,
        clickBackBtn: false,
      }
    },
    computed: {
      conversationId () {
        return this.$store.getters['chat/conversation/currentConversationId']
      },
      conversation () {
        return this.$store.getters['chat/data/conversation'](this.conversationId)
      },
      isTopicPanel () {
        return this.$store.getters['chat/sendbox/isTopicPanel']
      },
      isGroup () {
        return !this.conversation.is_private
      },
    },
    watch: {
      isTopicPanel (v) {
        this.innerActiveSidePanel = v
      },
    },
    methods: {
      closePanel () {
        this.$store.commit('chat/sendbox/isTopicPanel', false)
        this.innerActiveSidePanel = false
      },
      clickBackButton () {
        this.clickBackBtn = !this.clickBackBtn
      },
    },
  }
</script>
