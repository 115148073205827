<template>
  <div class="attachment-image">
    <div
      class="image-thumb"
      @click="show = true"
    >
      <img
        :src="content.thumb"
      >
    </div>
    <preview-image
      :show.sync="show"
      :content="content"
    />
  </div>
</template>

<script>
  import PreviewImage from './preview/PreviewImage'

  export default {
    components: {
      PreviewImage,
    },
    props: {
      content: {
        required: true,
        type: Object,
      },
    },
    data () {
      return {
        show: false,
        download: false,
      }
    },
    computed: {},
  }
</script>
